import React from "react";

const RequestSvg = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.91602 11.4585C7.91602 12.2669 8.54103 12.9169 9.30769 12.9169H10.8743C11.541 12.9169 12.0827 12.3502 12.0827 11.6419C12.0827 10.8835 11.7494 10.6085 11.2577 10.4335L8.74935 9.55853C8.25768 9.38353 7.92436 9.11686 7.92436 8.35019C7.92436 7.65019 8.46601 7.0752 9.13268 7.0752H10.6993C11.466 7.0752 12.091 7.7252 12.091 8.53353"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 6.25V13.75"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.3327 9.99984C18.3327 14.5998 14.5993 18.3332 9.99935 18.3332C5.39935 18.3332 1.66602 14.5998 1.66602 9.99984C1.66602 5.39984 5.39935 1.6665 9.99935 1.6665"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.166 2.5V5.83333H17.4993"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.3327 1.6665L14.166 5.83317"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default RequestSvg;
