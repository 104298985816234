import React from "react";
import PropTypes from "prop-types";
import { CurrencyInput } from "./CurrencyConverter.style";
class NumberField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  onChange(value) {
    this.props.onChange(value);
  }

  toCurrency(number) {
    const formatter = new Intl.NumberFormat(this.props.country || `en-AU`, {
      style: "decimal",
      maximumFractionDigits: 2,
      //   currency: this.props.currency || `INR`,
      //   signDisplay: "never",
    });

    return formatter.format(number);
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <div>
        {this.state.isEditing ? (
          <CurrencyInput
            type="number"
            step="0.01"
            min="0" inputMode="numeric" pattern="[0-9]*"
            defaultValue={this.props.defaultValue}
            name={this.props.name}
            value={this.props.value}
            onChange={this.onChange.bind(this)}
            onBlur={this.toggleEditing.bind(this)}
          />
        ) : (
          <CurrencyInput
            type="text"
            size="large"
            name={this.props.name}
            value={this.toCurrency(this.props.value)}
            onFocus={this.toggleEditing.bind(this)}
            inputMode="numeric" pattern="[0-9]*"
            // readOnly
          />
        )}
      </div>
    );
  }
}

NumberField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  //   currency: PropTypes.string,
  country: PropTypes.string,
  onChange: PropTypes.func,
};

export default NumberField;
