import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuDropDownLink from "../../@cashremit/TopNav/MenuDropdown";

import { NavItem, NavWrapper, SigupBTN } from "./Topnav.style";
import { Box } from "@chakra-ui/react";
export class NavbarLinks extends Component {
  render() {
    const { links, auth_links, isBgColor, user } = this.props;
    return (
      <NavWrapper>
        <MenuDropDownLink />

        {links.map((link, index) => (
          <NavItem isBgColor={isBgColor} to={link.href} key={index}>
            <Link key={index} to={link.href}>
              {link.title}
            </Link>
          </NavItem>
        ))}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!user && (
            <NavItem isBgColor={isBgColor} to={"/login"}>
              <Link to={"/login"}>Login</Link>
            </NavItem>
          )}

          {auth_links.map((link, index) => (
            <SigupBTN to={link.href} key={index}>
              <Link key={index} to={link.href}>
                {link.title}
              </Link>
            </SigupBTN>
          ))}
        </Box>
      </NavWrapper>
    );
  }
}

export default NavbarLinks;
