import React from "react";
import styled from "styled-components";
// import MobileMoneyIco from "@cashremit/cr-streamline-icons/lib/regular/08-Money-Payments-Finance/01-Accounting-Billing/AccountingInvoiceHand";
// import MobileTopUpIco from "@cashremit/cr-streamline-icons/lib/regular/08-Money-Payments-Finance/10-Modern-Payments/WirelessPaymentSmartphone1";
// import BankTransferIco from "@cashremit/cr-streamline-icons/lib/regular/08-Money-Payments-Finance/04-Finance-Savings/SavingBankCash";

import { Select } from "antd";
import { ChevronDownIcon } from "@chakra-ui/icons";

const IcoWrapper = styled.span`
  display: inline-flex;
  svg {
    font-size: 28px;
    position: relative;
    top: 8px;
  }
`;

const mobileCode = [
  { label: "+91", value: 91 },
  { label: "+86", value: 86 },
  { label: "+87", value: 87 },
];

const MobileMoneyIcon = () => (
  <IcoWrapper>
    <ChevronDownIcon />
  </IcoWrapper>
);

const BankTransferIcon = () => (
  <IcoWrapper>
    <ChevronDownIcon />
  </IcoWrapper>
);

const MobileTopUpIcon = () => (
  <IcoWrapper>
    <ChevronDownIcon />
  </IcoWrapper>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="close" opacity="0.31">
      <g id="Group_202" data-name="Group 202">
        <path
          id="Path_250"
          data-name="Path 250"
          d="M16,1.617,14.383,0,8,6.383,1.617,0,0,1.617,6.383,8,0,14.383,1.617,16,8,9.617,14.383,16,16,14.383,9.617,8Z"
        />
      </g>
    </g>
  </svg>
);

const DeliveryMethodsWrap = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  min-height: 50px;
`;

const Label = styled.label`
  margin: 0.3rem 1rem;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #8995b8;
`;

const Selection = styled(Select)`
  min-width: 12rem;
`;

export const DeliveryMethods = ({ label, options = [], ...props }) => {
  return (
    <DeliveryMethodsWrap>
      {label && <Label>{label}</Label>}
      <Selection {...props}>
        {options.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Selection>
    </DeliveryMethodsWrap>
  );
};

export {
  mobileCode,
  MobileMoneyIcon,
  BankTransferIcon,
  MobileTopUpIcon,
  CloseIcon,
};
