import React from 'react';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1001;
`;
const StyledLoader = styled.div`
	margin: auto;
	height: 100px;
	width: 100px;
	> div {
		height: inherit;
		width: inherit;
		position: absolute;
		> div {
			height: 15px;
			width: 15px;
			border-radius: 50%;
			background: #274972;
			position: absolute;
			top: 0%;
			right: 50%;
			transform: translate(50%, 0%) scale(0);
			animation-name: scale;
			animation-duration: 1.1s;
			animation-timing-function: ease;
			animation-iteration-count: infinite;
		}
		&:nth-child(2) {
			transform: rotate(30deg);
			> div {
				animation-delay: 0.1s;
			}
		}
		&:nth-child(3) {
			transform: rotate(60deg);
			> div {
				animation-delay: 0.2s;
			}
		}
		&:nth-child(4) {
			transform: rotate(90deg);
			> div {
				animation-delay: 0.3s;
			}
		}
		&:nth-child(5) {
			transform: rotate(120deg);
			> div {
				animation-delay: 0.4s;
			}
		}
		&:nth-child(6) {
			transform: rotate(150deg);
			> div {
				animation-delay: 0.5s;
			}
		}
		&:nth-child(7) {
			transform: rotate(180deg);
			> div {
				animation-delay: 0.6s;
			}
		}
		&:nth-child(8) {
			transform: rotate(210deg);
			> div {
				animation-delay: 0.7s;
			}
		}
		&:nth-child(9) {
			transform: rotate(240deg);
			> div {
				animation-delay: 0.8s;
			}
		}
		&:nth-child(10) {
			transform: rotate(270deg);
			> div {
				animation-delay: 0.9s;
			}
		}
		&:nth-child(11) {
			transform: rotate(300deg);
			> div {
				animation-delay: 1s;
			}
		}
		&:nth-child(12) {
			transform: rotate(330deg);
			> div {
				animation-delay: 1.1s;
			}
		}
	}
	@keyframes scale {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
`;

const Loader = (props) => {
	return (
		<LoaderWrapper>
			<StyledLoader className="loader">
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<div></div>
				</div>
			</StyledLoader>
		</LoaderWrapper>
	);
};

export default Loader;
