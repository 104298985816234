import React from 'react'

export const SendIconSvg = (props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M4 9.33325H16" stroke="#274972" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 16H10.6667" stroke="#274972" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 22.6667H16" stroke="#274972" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M27.6667 16H17" stroke="#274972" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.6667 20L27.6667 16L23.6667 12" stroke="#274972" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)