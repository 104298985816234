import styled from "styled-components";

// START DELIVERY METHOD STYLE
export const DeliveryMethodWrapper = styled.div`
  display: flex;

  .ant-radio-group {
    display: flex;
    align-items: center;
  }

  .ant-radio-button-wrapper {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 138%;
    color: #1a314c;
    background: #f4f7fb;
    border: transparent;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgba(137, 149, 184, 0.5);
    color: #1a314c;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: rgba(137, 149, 184, 0.5);
    color: #1a314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: transparent;
  }

  @media (max-width: 767px) {
    .ant-radio-button-wrapper {
      font-size: 14px;
    }
  }
`;

// END DELIVERY METHOD STYLE
