import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  PopoverHeader,
  SimpleGrid,
  PopoverCloseButton,
  Box,
} from "@chakra-ui/react";

import styled from "styled-components";
import { ChevronDownIcon } from "@chakra-ui/icons";

const PopoverContentCR = styled(PopoverContent)`
  margin-top: 1em;
  border-radius: 5px;
  max-width: 860px;
  padding: 3em;
`;

const PopoverNavItem = styled.ul`
  display: block;
`;

const PopoverNavItemList = styled.li`
  display: block;
  margin-left: -7px !important;
`;

const HeaderText = styled.h5`
  display: flex;
  font-size: 20px;
  color: #2d4c75;
  padding: 0 3em;
`;
const PopOverButton = styled.a`
  background: transparent !important;
  color: #305178;
  border: none;
  font-weight: 500 !important;
  /* margin-left: 14px; */
  font-size: 16px;
  letter-spacing: 1px !important;
`;

const PopoverText = styled.p`
  display: flex;
  border-bottom: solid 1px #dddddd;
  color: #2d4c75;
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  padding: 2em;
`;

const PopoverLink = styled.a`
  display: flex;
  color: #2c4d74;
`;

const PopoverLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const PopoverLinkContent = styled.span`
  display: flex;
  &.d-block {
    font-size: 10px;
    color: #666666;
  }
`;

function ControlledUsage() {
  return (
    <>
      <Popover trigger="false">
        <PopoverTrigger>
          <PopOverButton as={Button} rightIcon={<ChevronDownIcon />}>
            Product
          </PopOverButton>
        </PopoverTrigger>
        <PopoverContentCR color="blue" bg="white">
          <PopoverArrow />
          <PopoverBody className="testtest">
            <PopoverArrow bg="pink.500" />
            <PopoverCloseButton bg="purple.500" />
            <PopoverHeader>
              <PopoverText>
                Cashremit is connecting customers to different business
                opporunities not just the remittance, take the advantage of
                other investment platforms.
              </PopoverText>
            </PopoverHeader>
            <SimpleGrid columns={[2, null, 3]} spacing="40px">
              <Box>
                <HeaderText>Business</HeaderText>
                <PopoverNavItem>
                  <PopoverNavItemList>
                    <PopoverLink href="/partnerships">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>Partnerships</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Build a progressive
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/network-providers">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>
                          Network Providers
                        </PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Join the network service
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/invest-in-properties">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>
                          Invest in Properties
                        </PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Gurrantee Properties investment
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                </PopoverNavItem>
              </Box>
              <Box>
                <HeaderText>Payment</HeaderText>
                <PopoverNavItem>
                  <PopoverNavItemList>
                    <PopoverLink href="/digital-card-payment">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>
                          Digital card payment
                        </PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Build a progressive
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/wallet-system">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>Wallet system</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Join the network service
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/help-with-payid">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>PayID Payment</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Gurrantee Properties investment
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                </PopoverNavItem>
              </Box>
              <Box>
                <HeaderText>Trading</HeaderText>
                <PopoverNavItem>
                  <PopoverNavItemList>
                    <PopoverLink href="/invest-in-crypto">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>Invest: Cyrpto</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Build a progressive
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/investors-club">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>investors club</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Join the network service
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                  <PopoverNavItemList>
                    <PopoverLink href="/investment-news">
                      <PopoverLinkWrapper>
                        <PopoverLinkContent>Investment news</PopoverLinkContent>
                        <PopoverLinkContent className="d-block">
                          Gurrantee Properties investment
                        </PopoverLinkContent>
                      </PopoverLinkWrapper>
                    </PopoverLink>
                  </PopoverNavItemList>
                </PopoverNavItem>
              </Box>
            </SimpleGrid>
          </PopoverBody>
        </PopoverContentCR>
      </Popover>
      <Popover trigger="hover">
        <PopoverTrigger>
          <PopOverButton as={Button} rightIcon={<ChevronDownIcon />}>
            Australia
          </PopOverButton>
        </PopoverTrigger>
        <PopoverContentCR color="blue" bg="white" w="800">
          <PopoverArrow />
          <PopoverHeader>
            <Box w="100%">
              Choose any countries you like to send money from{" "}
            </Box>
          </PopoverHeader>
          <PopoverBody>
            <PopoverNavItem>
              <PopoverNavItemList>
                <a href="/">Australia</a>
              </PopoverNavItemList>
              <PopoverNavItemList>
                <a href="/">Canada</a>
              </PopoverNavItemList>
              <PopoverNavItemList>
                <a href="/">News Zealand</a>
              </PopoverNavItemList>
            </PopoverNavItem>
          </PopoverBody>
        </PopoverContentCR>
      </Popover>
    </>
  );
}
export default ControlledUsage;
