import styled, { css } from "styled-components";

export const Wrapper = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 0;
  /* background-color: ${({ theme }) => theme.palette.Green100}; */
  background-color: #305178;
  // border-top: solid 6px #d8e028;
  padding-top: 6em;

  @media (min-width: 992px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 6em; */
    /* padding-bottom: 3em; */
  }

  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 24px;
  }
`;

export const NavComponentWrapper = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  /* justify-content: center; */
  padding-top: 26px;
  /* max-width: 1170px;
  margin: 0 auto; */

  @media (max-width: 1199px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 599px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const ContactLink = styled.span`
  // padding: 5px 0 8px 0;
  font-size: 16px;
  line-height: 160%;

  svg {
    fill: #d6df22;
    @media (max-width: 1199px) {
      width: 16px;
    }
  }
  @media (min-width: 992px) {
  }

  a {
    /* color: #1a314c; */
    color: white;
  }
`;

export const LinkList = styled.ul`
  padding-bottom: 14px;
  font-family: "Poppins", sans-serif;
  padding-left: 0;

  @media (min-width: 992px) {
    /* padding-right: 4em; */
    padding-left: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Expander = styled.h5`
  display: flex;
  padding: 0;
  /* color: #1a314c; */
  color: white;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
`;

export const NavLink = styled.li`
  display: flex;
  font-size: 14px;
  line-height: 1;
  position: relative;
  font-size: 15px;
  line-height: 34px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  /* color: white; */

  @media (min-width: 768px) {
    line-height: 2;
  }

  a,
  button {
    width: 100%;
    font-size: 16px;
    /* color: #1a314c; */
    color: white;
    width: 100%;
    padding: 5px 0 !important;
    text-decoration: none;

    @media (min-width: 992px) {
      padding: 0 !important;
      margin-top: 0;
    }

    @media (max-width: 1199px) {
      font-size: 12px;
      line-height: 20px;
    }

    &:hover,
    &:active {
      color: #d6df22;
    }
  }

  button {
    display: flex;
    cursor: pointer;
    width: 100%;
  }
`;

const containerStyle = css`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  ${containerStyle}
  @media (min-width: 992px ) {
    padding-right: 16px;
  }

  @media (max-width: 767px) {
    align-items: center;
  }
`;

export const StyledP = styled.p`
  font-weight: 600;
`;

export const SocialIconsContainer = styled.div`
  margin: 0 0;
  display: flex;
  margin-top: 38px;

  @media (min-width: 992px) {
    margin-bottom: 16px;
  }
  @media (max-width: 599px) {
    margin: 10px 0;
  }
`;

export const SocialIcon = styled.a`
  margin: 0 14px;

  & svg {
    /* width: 20px; */
    margin: 0 auto;
  }

  .facebook_icon {
    width: 21px;
  }
  .twitter_icon {
    width: 30px;
  }
  .instagram_icon {
    width: 30px;
  }

  span {
    display: flex;
    color: #274972;
    font-size: sm;
    font-size: 37px;
    width: 36px;
    height: 35px;
    text-align: center;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  @media (min-width: 992px) {
    margin: 0 12px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 599px) {
    margin: 0 12px 0 0;
  }
`;

export const NavigationWrapper = styled.div`
  width: 100%;

  /* @media (min-width: 400px) {
    max-width: 320px;
  } */

  @media (min-width: 992px) {
    width: auto;
    padding-right: 18px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

// Sub footer

export const Bar = styled.div`
  align-items: center;
  display: flex;
  background: ${({ theme }) => theme.palette.Green100};
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
  border-top: solid 1px #c7d3de;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  line-height: 1;
  width: 100%;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const SubfooterContent = styled.div`
  text-align: center;
  display: flex;

  @media (min-width: 992px) {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 10px 9em;
    line-height: 1.5;
  }
`;

export const SafeAppWrapper = styled.div`
  display: flex;
  position: relative;
  top: 5em;
  background: white;
  max-width: 1170px;
  margin: 0 auto;
`;
export const FooterBottom = styled.div`
  // max-width: 1170px;
  width: 100%;
  padding: 32px 15px 20px;
  margin: 20px 0 auto;
  background-color: #274972;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;
export const FBottomtext = styled.p`
  max-width: 1170px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  span {
    color: #ffffff;
  }
`;
export const FootDescription = styled.div`
  svg {
    max-height: 40px;
  }
  /* padding-right: 3em; */
`;
export const Description = styled.p`
  /* color: #1a314c; */
  color: #ffffff;
  /* max-width: 350px; */
  /* max-width: 488px; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;

  margin-bottom: 56px;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`;
