import styled from "styled-components";

export const ReferFriendWrapper = styled.div`
  display: flex;
  margin-top: ${({ classToggle }) => (classToggle ? "280px" : "120px")};

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 0px;
  }

  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

export const LeftTextWrapper = styled.div`
  display: flex;
  flex-flow: column;

  h6 {
    font-size: 20px;
    font-weight: 500;
    color: #305178;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 56px;
    line-height: 124%;
    letter-spacing: -2px;
    color: #305178;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    line-height: 160%;
    color: #0f1f33;
    margin-bottom: 60px;
  }

  span {
    font-size: 18px;
    line-height: 30px;
    color: #305178;
  }

  @media (max-width: 1199px) {
    h1 {
      font-size: 28px;
      letter-spacing: normal;
    }

    p {
      font-size: 18px;
    }
    span {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    align-items: center;

    h6 {
      font-size: 18px;
    }

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    align-items: center;
    h1 {
      font-size: 22px;
      letter-spacing: normal;
    }

    p {
      font-size: 14px;
      margin-bottom: 40px;
    }

    span {
      font-size: 14px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: inline-block;

  button {
    border-radius: 50px;
  }
`;

export const RightImageWrapper = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  justify-content: start;
  width: 100%;
  max-width: 86%;

  .ant-image {
    width: 100%;
  }

  img {
    height: 625px;
    width: 100%;
    /* height: 586px; */
    object-fit: cover;
    border-radius: 10px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    img {
      height: 452px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    img {
      height: 480px;
    }
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
