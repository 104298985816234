import React, { useEffect, useState } from "react";
import {
	CustomDDWrapper,
	CustomDDButton,
	CustomDDContentWrapper,
	CustomDDContentItem,
	CustomDDSearchInput,
	CustomDDContryWithFlag,
	CustomBtnContent,
	IconWrapper,
} from "./CurrencyConverter.style";

const CustomCountryDD = (props) => {
	const { data, onChange, defaultValue } = props;
	const [state, setState] = useState({
		selectedValue: data.find((d) => d.value === defaultValue),
	});
	useEffect(() => {
		console.log({ data, state });
		if (!state.selectedValue) {
			setState((s) => ({
				selectedValue: data.find(() => true),
			}));
		}
	}, []);
	const countryChangeHanler = (v) => {
		onChange(v);
		setState({ selectedValue: v });
	};
	const btnClickHandler = () => {
		let dd = document.getElementById(`custom-dd-${props.idx}`);
		let input = document.getElementById(`custom-input-${props.idx}`);
		dd.classList.add("show");
		input.focus();
	};

	const closeDD = () => {
		let dd = document.getElementById(`custom-dd-${props.idx}`);
		if (dd.classList.contains("show")) {
			setTimeout(() => {
				dd.classList.remove("show");
			}, 250);
		}
	};

	const filterHandler = () => {
		var input, filter, div, a, i, txtValue;
		input = document.getElementById(`custom-input-${props.idx}`);
		filter = input.value.toUpperCase();
		div = document.getElementById(`custom-dd-${props.idx}`);
		a = div.getElementsByTagName("div");

		for (i = 0; i < a.length; i++) {
			txtValue = a[i].textContent || a[i].innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				a[i].style.display = "";
			} else {
				a[i].style.display = "none";
			}
		}
	};

	const downArrow = () => (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			xmlspacespace="preserve"
		>
			<g>
				<g>
					<path
						d="M505.752,123.582c-8.331-8.331-21.839-8.331-30.17,0L256,343.163L36.418,123.582c-8.331-8.331-21.839-8.331-30.17,0
        s-8.331,21.839,0,30.17l234.667,234.667c8.331,8.331,21.839,8.331,30.17,0l234.667-234.667
        C514.083,145.42,514.083,131.913,505.752,123.582z"
					/>
				</g>
			</g>
		</svg>
	);

	return state.selectedValue ? (
		<CustomDDWrapper>
			<CustomDDButton onClick={btnClickHandler}>
				<CustomDDContryWithFlag>
					<CustomBtnContent>
						<img
							src={state.selectedValue.flag}
							height={36}
							width={36}
							alt="flag"
						/>
						<span>{state.selectedValue.name}</span>
					</CustomBtnContent>
					<IconWrapper>{downArrow()}</IconWrapper>
				</CustomDDContryWithFlag>
			</CustomDDButton>
			<CustomDDContentWrapper id={`custom-dd-${props.idx}`}>
				<CustomDDSearchInput
					type="text"
					placeholder="Search.."
					id={`custom-input-${props.idx}`}
					onKeyUp={filterHandler}
					onBlur={closeDD}
					tabIndex="1"
					autoFocus
				/>
				{data &&
					data.map((c, i) => (
						<CustomDDContentItem
							key={i}
							onClick={() => countryChangeHanler(c)}
							className={
								c.value === state.selectedValue.value ? "selected" : ""
							}
						>
							<CustomDDContryWithFlag item={true}>
								<img src={c.flag} height={36} width={36} alt="flag" />
								<span>{c.name}</span>
							</CustomDDContryWithFlag>
						</CustomDDContentItem>
					))}
			</CustomDDContentWrapper>
		</CustomDDWrapper>
	) : (
		<p></p>
	);
};

export default CustomCountryDD;
