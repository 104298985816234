import React from "react";

const LockSvg = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9999 28.9167C21.5002 28.9167 22.7165 27.7004 22.7165 26.2001C22.7165 24.6997 21.5002 23.4834 19.9999 23.4834C18.4995 23.4834 17.2832 24.6997 17.2832 26.2001C17.2832 27.7004 18.4995 28.9167 19.9999 28.9167Z"
          fill="#305178"
        />
        <path
          d="M30.4663 15.8833V13.7999C30.4663 9.29992 29.383 3.33325 19.9997 3.33325C10.6163 3.33325 9.53301 9.29992 9.53301 13.7999V15.8833C4.86634 16.4666 3.33301 18.8333 3.33301 24.6499V27.7499C3.33301 34.5833 5.41634 36.6666 12.2497 36.6666H27.7497C34.583 36.6666 36.6663 34.5833 36.6663 27.7499V24.6499C36.6663 18.8333 35.133 16.4666 30.4663 15.8833ZM19.9997 31.2333C17.2163 31.2333 14.9663 28.9666 14.9663 26.1999C14.9663 23.4166 17.233 21.1666 19.9997 21.1666C22.7663 21.1666 25.033 23.4333 25.033 26.1999C25.033 28.9833 22.783 31.2333 19.9997 31.2333ZM12.2497 15.7333C12.1163 15.7333 11.9997 15.7333 11.8663 15.7333V13.7999C11.8663 8.91658 13.2497 5.66659 19.9997 5.66659C26.7497 5.66659 28.133 8.91658 28.133 13.7999V15.7499C27.9997 15.7499 27.883 15.7499 27.7497 15.7499H12.2497V15.7333Z"
          fill="#305178"
        />
      </svg>
    </>
  );
};

export default LockSvg;
