import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, GridItem, Heading, Image } from "@chakra-ui/react";
import { Grid as ReactGrid } from "react-flexbox-grid";
import QuotesSvg from "assets/svg/QuotesSvg";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const TestimonialNew = () => {
  const [state, setState] = useState({
    slideIndex: 0,
    updateCount: 0,
  });

  // console.log("state", state);

  const slider = useRef();

  function next() {
    slider.current.slickNext();
  }
  function previous() {
    slider.current.slickPrev();
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => {},
    // setState((state) => ({ updateCount: state.updateCount + 1 })),
    beforeChange: (current, next) => setState({ slideIndex: next }),
    arrows: false,
  };

  return (
    <Box sx={{ display: "inline-block", w: "100%" }}>
      <ReactGrid>
        <Box sx={{ display: "inline-block", w: "100%", position: "relative" }}>
          <Slider ref={slider} {...settings}>
            {[...Array(3)]?.map((item, index) => (
              <Grid
                templateColumns="repeat(12, 1fr)"
                gap={20}
                // sx={{ display: "grid !important" }}
                key={index}
                display={{
                  base: "inline-block !important",
                  lg: "grid !important",
                }}
              >
                <GridItem colStart={{ base: 1 }} colEnd={{ base: 13, lg: 5 }}>
                  <Box sx={{ display: "inline-block", w: "100%" }}>
                    <Image
                      src="/assets/customer.png"
                      alt="img"
                      boxSize="100%"
                      objectFit="cover"
                      sx={{
                        h: "467px",
                        w: "100%",
                      }}
                    />
                  </Box>
                </GridItem>
                <GridItem colStart={{ base: 1, lg: 5 }} colEnd={{ base: 13 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      w: "100%",
                      h: "100%",
                    }}
                  >
                    <Heading
                      as="h4"
                      fontSize={{
                        base: "24px",
                        md: "24px",
                        lg: "40px",
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#305178",
                        mt: "80px",
                      }}
                      mb={{ base: "20px", lg: "0px" }}
                    >
                      You guys are the best..very efficient and fast...I even
                      had to recommend your services to my friends, and they
                      have been using it regularly.thanks for delivering our
                      dollars.
                    </Heading>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: "auto",
                      }}
                    >
                      <Heading
                        as="h5"
                        fontSize={{
                          base: "18px",
                          md: "18px",
                          lg: "24px",
                        }}
                        sx={{
                          fontWeight: 600,
                          color: "#305178",
                          mb: "10px",
                        }}
                      >
                        Enyinna
                      </Heading>

                      <Heading
                        as="h5"
                        fontSize={{
                          base: "16px",
                          md: "16px",
                          lg: "18px",
                        }}
                        sx={{
                          fontWeight: 400,
                          color: "#305178",
                        }}
                      >
                        Job title example
                      </Heading>
                    </Box>

                    <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                      <QuotesSvg />
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            ))}
          </Slider>

          <Box
            sx={{
              display: "flex",
              gap: 20,
              // position: "absolute",
              justifyContent: "center",
              right: 0,
              bottom: 0,
            }}
            position={{ base: "unset", lg: "absolute" }}
            mt={{ base: "20px", lg: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                w: "40px",
                h: "40px",
                bg: state?.slideIndex === 0 ? "#a2b3c7" : "#305178",
                position: "relative",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={previous}
            >
              <FiChevronLeft color="white" fontSize="22px" />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                w: "40px",
                h: "40px",
                bg:
                  state?.slideIndex === [...Array(3)].length - 1
                    ? "#a2b3c7"
                    : "#305178",
                position: "relative",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={next}
            >
              <FiChevronRight color="white" fontSize="22px" />
            </Box>
          </Box>
        </Box>
      </ReactGrid>
    </Box>
  );
};

export default TestimonialNew;
