import React from "react";

const NewWhiteLogo = () => {
  return (
    <>
      <svg
        width="308"
        height="66"
        viewBox="0 0 308 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69 32.1442C69 29.3477 69.6196 26.8605 70.8588 24.6824C72.098 22.4775 73.8191 20.7701 76.0222 19.56C78.2528 18.3231 80.7725 17.7047 83.5813 17.7047C87.0236 17.7047 89.9702 18.592 92.421 20.3667C94.8719 22.1414 96.5104 24.5614 97.3366 27.6268H89.5709C88.9926 26.4437 88.1664 25.5429 87.0924 24.9244C86.046 24.306 84.8481 23.9968 83.4987 23.9968C81.3232 23.9968 79.5608 24.7362 78.2114 26.2151C76.8621 27.694 76.1874 29.6704 76.1874 32.1442C76.1874 34.618 76.8621 36.5944 78.2114 38.0733C79.5608 39.5522 81.3232 40.2916 83.4987 40.2916C84.8481 40.2916 86.046 39.9824 87.0924 39.3639C88.1664 38.7455 88.9926 37.8447 89.5709 36.6616H97.3366C96.5104 39.7269 94.8719 42.147 92.421 43.9217C89.9702 45.6695 87.0236 46.5434 83.5813 46.5434C80.7725 46.5434 78.2528 45.9383 76.0222 44.7283C73.8191 43.4914 72.098 41.784 70.8588 39.6059C69.6196 37.4279 69 34.9407 69 32.1442Z"
          fill="white"
        />
        <path
          d="M119.713 41.3403H108.891L107.156 46.3417H99.7621L110.254 18.0274H118.433L128.925 46.3417H121.448L119.713 41.3403ZM117.896 36.0162L114.302 25.6504L110.75 36.0162H117.896Z"
          fill="white"
        />
        <path
          d="M142.61 46.624C140.49 46.624 138.589 46.2879 136.91 45.6157C135.23 44.9434 133.88 43.9485 132.861 42.631C131.87 41.3134 131.347 39.7269 131.292 37.8716H138.81C138.92 38.9203 139.292 39.7269 139.925 40.2916C140.558 40.8294 141.384 41.0983 142.403 41.0983C143.45 41.0983 144.276 40.8697 144.882 40.4126C145.488 39.9286 145.791 39.2698 145.791 38.4363C145.791 37.7371 145.543 37.159 145.047 36.7019C144.579 36.2448 143.987 35.8683 143.271 35.5726C142.582 35.2768 141.591 34.9407 140.297 34.5642C138.424 33.9995 136.896 33.4349 135.712 32.8702C134.528 32.3055 133.509 31.472 132.655 30.3695C131.801 29.267 131.374 27.8285 131.374 26.0538C131.374 23.4186 132.352 21.3616 134.307 19.8827C136.262 18.3769 138.81 17.624 141.949 17.624C145.143 17.624 147.718 18.3769 149.673 19.8827C151.629 21.3616 152.675 23.4321 152.813 26.0941H145.171C145.116 25.1799 144.772 24.4673 144.138 23.9564C143.505 23.4186 142.693 23.1498 141.701 23.1498C140.848 23.1498 140.159 23.3783 139.636 23.8354C139.113 24.2657 138.851 24.8975 138.851 25.7311C138.851 26.6453 139.292 27.3579 140.173 27.8688C141.054 28.3797 142.431 28.9309 144.304 29.5225C146.176 30.1409 147.691 30.7325 148.847 31.2972C150.031 31.8619 151.05 32.682 151.904 33.7575C152.758 34.8331 153.185 36.2179 153.185 37.9119C153.185 39.5253 152.758 40.9907 151.904 42.3083C151.078 43.6259 149.866 44.6746 148.269 45.4543C146.672 46.2341 144.785 46.624 142.61 46.624Z"
          fill="white"
        />
        <path
          d="M182.646 18.0274V46.3417H175.583V34.6852H164.595V46.3417H157.531V18.0274H164.595V29.1192H175.583V18.0274H182.646Z"
          fill="white"
        />
        <path
          d="M202.533 46.3417L196.502 35.6532H194.809V46.3417H187.745V18.0274H199.6C201.886 18.0274 203.827 18.4173 205.425 19.197C207.049 19.9768 208.261 21.0524 209.06 22.4237C209.858 23.7682 210.257 25.274 210.257 26.9411C210.257 28.8234 209.707 30.5039 208.605 31.9829C207.531 33.4618 205.934 34.5104 203.814 35.1289L210.505 46.3417H202.533ZM194.809 30.7728H199.187C200.481 30.7728 201.445 30.4636 202.079 29.8452C202.74 29.2267 203.07 28.3528 203.07 27.2235C203.07 26.1479 202.74 25.3009 202.079 24.6824C201.445 24.064 200.481 23.7548 199.187 23.7548H194.809V30.7728Z"
          fill="white"
        />
        <path
          d="M221.755 23.5531V29.2805H231.214V34.6046H221.755V40.816H232.453V46.3417H214.692V18.0274H232.453V23.5531H221.755Z"
          fill="white"
        />
        <path
          d="M269.878 18.0274V46.3417H262.815V29.3612L256.33 46.3417H250.629L244.103 29.3208V46.3417H237.039V18.0274H245.383L253.521 37.6296L261.576 18.0274H269.878Z"
          fill="white"
        />
        <path
          d="M282.021 18.0274V46.3417H274.958V18.0274H282.021Z"
          fill="white"
        />
        <path
          d="M308 18.0274V23.5531H300.317V46.3417H293.253V23.5531H285.57V18.0274H308Z"
          fill="white"
        />
        <path
          d="M25.6149 64.362L4.88502 52.8222C3.39978 51.9955 2.16645 50.8064 1.30895 49.3744C0.451445 47.9424 -1.86454e-05 46.3179 4.96864e-08 44.6644V21.5848C-0.000172905 19.9311 0.451189 18.3064 1.30869 16.8742C2.1662 15.442 3.39964 14.2526 4.88502 13.4258L25.6149 1.88616C27.1001 1.05933 28.7849 0.624023 30.4999 0.624023C32.2149 0.624023 33.8997 1.05933 35.3849 1.88616L56.115 13.4258C57.6004 14.2526 58.8338 15.442 59.6913 16.8742C60.5488 18.3064 61.0002 19.9311 61 21.5848V44.6644C61 46.3179 60.5486 47.9424 59.6911 49.3744C58.8336 50.8064 57.6003 51.9955 56.115 52.8222L35.3849 64.362C33.8997 65.1888 32.2149 65.624 30.4999 65.624C28.7849 65.624 27.1002 65.1888 25.6149 64.362Z"
          fill="white"
        />
        <path
          d="M30.2405 41.7753L24.4825 38.5936C24.1547 38.4124 23.8824 38.1519 23.6931 37.8381C23.5038 37.5244 23.4041 37.1684 23.4041 36.8061V30.4431C23.404 30.0808 23.5037 29.7248 23.693 29.4111C23.8823 29.0973 24.1546 28.8368 24.4825 28.6557L30.2405 25.4739C30.5683 25.2928 30.9402 25.1974 31.3188 25.1974C31.6974 25.1974 32.0693 25.2928 32.3972 25.4739L38.1551 28.6557C38.483 28.8368 38.8548 28.9321 39.2334 28.9321C39.612 28.9321 39.9839 28.8368 40.3118 28.6557L47.1816 24.86C47.4304 24.7225 47.6371 24.5247 47.7807 24.2865C47.9244 24.0484 48 23.7782 48 23.5033C48 23.2283 47.9244 22.9581 47.7807 22.72C47.6371 22.4818 47.4304 22.284 47.1816 22.1465L32.1371 13.8339C31.8883 13.6964 31.6059 13.624 31.3186 13.624C31.0312 13.624 30.749 13.6964 30.5001 13.8339L13.8186 23.0506C13.5698 23.1881 13.3631 23.3858 13.2194 23.624C13.0757 23.8622 13 24.1324 13 24.4074V42.8407C13 43.1157 13.0757 43.3859 13.2194 43.624C13.3631 43.8622 13.5698 44.06 13.8186 44.1975L30.5001 53.4141C30.749 53.5516 31.0312 53.624 31.3186 53.624C31.6059 53.624 31.8883 53.5516 32.1371 53.4141L47.1816 45.1017C47.4304 44.9642 47.6371 44.7664 47.7807 44.5283C47.9244 44.2901 48 44.02 48 43.745C48 43.47 47.9244 43.1998 47.7807 42.9617C47.6371 42.7235 47.4304 42.5258 47.1816 42.3883L40.3118 38.5926C39.9839 38.4114 39.612 38.3161 39.2334 38.3161C38.8548 38.3161 38.483 38.4114 38.1551 38.5926L32.3972 41.7743C32.0694 41.9556 31.6975 42.0511 31.3189 42.0513C30.9404 42.0515 30.5684 41.9563 30.2405 41.7753Z"
          fill="url(#paint0_linear_109_464)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_109_464"
            x1="13"
            y1="33.624"
            x2="48"
            y2="33.624"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6DF22" />
            <stop offset="1" stop-color="#C0C646" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default NewWhiteLogo;
