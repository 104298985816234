import styled, { css } from "styled-components";

export const Banner = styled.div`
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* min-height: 700px; */
  /* max-height: 786px; */
  overflow: visible;
  height: 100%;
  position: relative;

  padding: 4rem 0 3rem;
  /* LoadingBG */

  /* ${({ brandImg }) =>
    brandImg &&
    css`
      background-image: url(${brandImg});
    `} */

  /* ${({ brandbgPageImg }) =>
    brandbgPageImg &&
    css`
      background-image: url(${brandbgPageImg});
    `} */

  background-color: #eef5fb;
`;

export const TopNavWrapper = styled.div`
  /* position: absolute;
  left: 0;
  right: 0; */
  /* background: transparent; */
`;

export const BannerTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Heading = styled.h4`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  /* color: ${({ theme }) => theme.palette.Yellow10}; */
  color: #305178;
  /* margin-top: 128px; */
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 500;

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (max-width: 767px) {
    text-align: center;
  }

  @media (max-width: 575px) {
    font-size: 18px;
  }
`;

export const SeamLess = styled.h1`
  font-weight: 700;
  font-size: 56px;
  line-height: 112%;
  /* color: #ffffff; */
  color: #305178;
  width: 100%;
  max-width: 90%;
  margin-bottom: 32px;
  text-transform: uppercase;

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 50px;
  }

  @media (max-width: 767px) {
    font-size: 40px;
    text-align: center;
  }

  @media (max-width: 575px) {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 160%;
  color: #0f1f33;
  margin-bottom: 50px;

  width: 100%;
  max-width: 693px;

  /* opacity: 0.7; */

  @media (max-width: 991px) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    text-align: center;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

export const PageBanner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  overflow: visible;
`;

export const RightContentWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: -9%;

  @media (min-width: 992px) and (max-width: 1399px) {
    right: 0;
  }

  @media (max-width: 991px) {
    left: 0;
    right: 0;
    z-index: 1;
    top: 30px;
  }
`;
