import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
// import WordmarkLogo from "./logos/Wordmark";
import PillLogo from "./logos/Pill";
import NewLogo from "./logos/NewLogo";

const Logo = ({ type, size, className }) => {
  const logoProps = { size, className, type };
  const logoTypes = {
    brandbg: <PillLogo {...logoProps} />,
    // whitebg: <WordmarkLogo {...logoProps} />,
    whitebg: <NewLogo />,
  };
  return logoTypes[type];
};

Logo.propTypes = {
  /** Type of logo */
  type: PropTypes.oneOf(["brandbg", "whitebg"]),
  /** Size of the logo */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

Logo.defaultProps = {
  type: "brandbg",
  size: "sm",
};

export default withTheme(Logo);
