import React from "react";

const NewLogo = () => {
  return (
    <>
      <svg
        width="150"
        height="42"
        viewBox="0 0 199 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.7344 20.6942C44.7344 18.8864 45.1337 17.2785 45.9324 15.8706C46.7311 14.4452 47.8404 13.3415 49.2603 12.5593C50.6979 11.7597 52.3219 11.3599 54.1323 11.3599C56.3509 11.3599 58.25 11.9335 59.8296 13.0807C61.4092 14.228 62.4653 15.7924 62.9977 17.7739H57.9926C57.6199 17.0091 57.0874 16.4268 56.3952 16.027C55.7208 15.6272 54.9487 15.4273 54.079 15.4273C52.6769 15.4273 51.541 15.9053 50.6713 16.8614C49.8016 17.8174 49.3668 19.095 49.3668 20.6942C49.3668 22.2933 49.8016 23.5709 50.6713 24.5269C51.541 25.483 52.6769 25.961 54.079 25.961C54.9487 25.961 55.7208 25.7611 56.3952 25.3613C57.0874 24.9615 57.6199 24.3792 57.9926 23.6144H62.9977C62.4653 25.596 61.4092 27.1604 59.8296 28.3076C58.25 29.4374 56.3509 30.0024 54.1323 30.0024C52.3219 30.0024 50.6979 29.6113 49.2603 28.8291C47.8404 28.0295 46.7311 26.9257 45.9324 25.5177C45.1337 24.1098 44.7344 22.5019 44.7344 20.6942Z"
          fill="#305178"
        />
        <path
          d="M77.4199 26.6389H70.4447L69.3265 29.872H64.561L71.3232 11.5685H76.5946L83.3568 29.872H78.538L77.4199 26.6389ZM76.2485 23.1972L73.9323 16.4963L71.6427 23.1972H76.2485Z"
          fill="#305178"
        />
        <path
          d="M92.1771 30.0545C90.8105 30.0545 89.5858 29.8372 88.5031 29.4027C87.4205 28.9681 86.5508 28.325 85.8941 27.4732C85.2551 26.6215 84.9179 25.596 84.8824 24.3966H89.7278C89.7988 25.0745 90.0384 25.596 90.4466 25.961C90.8548 26.3086 91.3873 26.4825 92.044 26.4825C92.7184 26.4825 93.2509 26.3347 93.6414 26.0392C94.0318 25.7263 94.2271 25.3005 94.2271 24.7616C94.2271 24.3097 94.0673 23.936 93.7479 23.6405C93.4461 23.345 93.0645 23.1016 92.6031 22.9104C92.1594 22.7192 91.5204 22.5019 90.6862 22.2586C89.4793 21.8935 88.4943 21.5285 87.7311 21.1635C86.9679 20.7985 86.3112 20.2596 85.761 19.5469C85.2108 18.8343 84.9357 17.9043 84.9357 16.7571C84.9357 15.0536 85.5657 13.7239 86.8259 12.7678C88.086 11.7944 89.7278 11.3077 91.7511 11.3077C93.81 11.3077 95.4695 11.7944 96.7296 12.7678C97.9898 13.7239 98.6642 15.0623 98.753 16.7832H93.8277C93.7922 16.1922 93.5704 15.7315 93.1621 15.4013C92.7539 15.0536 92.2303 14.8798 91.5914 14.8798C91.0412 14.8798 90.5975 15.0275 90.2603 15.323C89.923 15.6012 89.7544 16.0096 89.7544 16.5485C89.7544 17.1395 90.0384 17.6001 90.6063 17.9304C91.1743 18.2606 92.0617 18.617 93.2686 18.9994C94.4755 19.3992 95.4517 19.7816 96.1972 20.1466C96.9604 20.5116 97.6171 21.0418 98.1673 21.7371C98.7175 22.4324 98.9926 23.3276 98.9926 24.4227C98.9926 25.4656 98.7175 26.4129 98.1673 27.2647C97.6348 28.1164 96.8539 28.7943 95.8244 29.2984C94.795 29.8025 93.5792 30.0545 92.1771 30.0545Z"
          fill="#305178"
        />
        <path
          d="M117.981 11.5685V29.872H113.428V22.3368H106.347V29.872H101.794V11.5685H106.347V18.7387H113.428V11.5685H117.981Z"
          fill="#305178"
        />
        <path
          d="M130.798 29.872L126.912 22.9625H125.82V29.872H121.267V11.5685H128.908C130.381 11.5685 131.633 11.8205 132.662 12.3246C133.709 12.8287 134.49 13.524 135.005 14.4105C135.52 15.2796 135.777 16.253 135.777 17.3307C135.777 18.5475 135.422 19.6338 134.712 20.5899C134.02 21.5459 132.99 22.2238 131.624 22.6236L135.937 29.872H130.798ZM125.82 19.8077H128.642C129.476 19.8077 130.097 19.6078 130.506 19.208C130.932 18.8082 131.145 18.2433 131.145 17.5132C131.145 16.8179 130.932 16.2704 130.506 15.8706C130.097 15.4708 129.476 15.2709 128.642 15.2709H125.82V19.8077Z"
          fill="#305178"
        />
        <path
          d="M143.187 15.1405V18.843H149.284V22.2846H143.187V26.2999H150.083V29.872H138.635V11.5685H150.083V15.1405H143.187Z"
          fill="#305178"
        />
        <path
          d="M174.203 11.5685V29.872H169.651V18.8951L165.471 29.872H161.797L157.591 18.869V29.872H153.038V11.5685H158.416L163.661 24.2401L168.852 11.5685H174.203Z"
          fill="#305178"
        />
        <path
          d="M182.03 11.5685V29.872H177.477V11.5685H182.03Z"
          fill="#305178"
        />
        <path
          d="M198.774 11.5685V15.1405H193.822V29.872H189.269V15.1405H184.317V11.5685H198.774Z"
          fill="#305178"
        />
        <path
          d="M16.316 41.2386L3.11162 33.7723C2.16556 33.2374 1.37996 32.4681 0.83376 31.5416C0.287557 30.6151 -1.18766e-05 29.5641 3.16488e-08 28.4942V13.5617C-0.000110135 12.4918 0.287394 11.4406 0.8336 10.514C1.37981 9.58733 2.16547 8.81783 3.11162 8.28283L16.316 0.816727C17.262 0.281763 18.3351 0.00012207 19.4275 0.00012207C20.52 0.00012207 21.5931 0.281763 22.5392 0.816727L35.7436 8.28283C36.6898 8.81783 37.4754 9.58733 38.0216 10.514C38.5678 11.4406 38.8553 12.4918 38.8552 13.5617V28.4942C38.8552 29.5641 38.5677 30.6151 38.0215 31.5416C37.4753 32.4681 36.6897 33.2374 35.7436 33.7723L22.5392 41.2386C21.5931 41.7735 20.5199 42.0551 19.4275 42.0551C18.3351 42.0551 17.262 41.7735 16.316 41.2386Z"
          fill="#305178"
        />
        <path
          d="M18.6906 26.3665L15.0054 24.2826C14.7955 24.1639 14.6213 23.9933 14.5001 23.7878C14.3789 23.5823 14.3151 23.3492 14.3151 23.1118V18.9442C14.3151 18.7069 14.3789 18.4738 14.5001 18.2683C14.6212 18.0628 14.7955 17.8921 15.0054 17.7735L18.6906 15.6895C18.9004 15.5709 19.1385 15.5084 19.3808 15.5084C19.6231 15.5084 19.8611 15.5709 20.071 15.6895L23.7562 17.7735C23.966 17.8921 24.204 17.9546 24.4463 17.9546C24.6886 17.9546 24.9267 17.8921 25.1365 17.7735L29.5334 15.2874C29.6926 15.1973 29.8249 15.0678 29.9168 14.9118C30.0088 14.7558 30.0572 14.5789 30.0572 14.3988C30.0572 14.2187 30.0088 14.0417 29.9168 13.8857C29.8249 13.7298 29.6926 13.6002 29.5334 13.5102L19.9045 8.06557C19.7452 7.97551 19.5646 7.9281 19.3806 7.9281C19.1967 7.9281 19.0161 7.97551 18.8568 8.06557L8.1802 14.1023C8.02091 14.1923 7.88862 14.3219 7.79665 14.4779C7.70468 14.6339 7.65626 14.8108 7.65625 14.9909V27.0643C7.65626 27.2445 7.70468 27.4214 7.79665 27.5774C7.88862 27.7334 8.02091 27.8629 8.1802 27.953L18.8568 33.9897C19.0161 34.0798 19.1967 34.1272 19.3806 34.1272C19.5646 34.1272 19.7452 34.0798 19.9045 33.9897L29.5334 28.5453C29.6926 28.4552 29.8249 28.3257 29.9168 28.1697C30.0088 28.0137 30.0572 27.8368 30.0572 27.6566C30.0572 27.4765 30.0088 27.2996 29.9168 27.1436C29.8249 26.9876 29.6926 26.8581 29.5334 26.768L25.1365 24.2819C24.9267 24.1633 24.6886 24.1008 24.4463 24.1008C24.204 24.1008 23.966 24.1633 23.7562 24.2819L20.071 26.3659C19.8612 26.4846 19.6232 26.5472 19.3809 26.5473C19.1386 26.5474 18.9005 26.4851 18.6906 26.3665Z"
          fill="url(#paint0_linear_109_523)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_109_523"
            x1="7.65625"
            y1="21.0276"
            x2="30.0572"
            y2="21.0276"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6DF22" />
            <stop offset="1" stop-color="#C0C646" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default NewLogo;
