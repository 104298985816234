import styled, { css, keyframes } from "styled-components";
import { Input, Radio, Select } from "antd";

// START CURRENCY CONVERTER STYLE

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 5px;
`;

export const StyledSelectRecipientWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

// START CUSTOM DROPDOWN COUNTRY
export const CustomDDWrapperAddFund = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const CustomDDButtonDboard = styled.button`
  background-color: transparent;
  width: 123px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1a4975;
  border-radius: 6px;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 1px #1a4975;
  }

  @media (max-width: 767px) {
    height: 40px;
    padding: 5px;
    width: 260px;
  }
`;

export const CustomDDButtonAddFund = styled.button`
  background-color: transparent;
  width: 100%;
  height: 64px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1a4975;
  border-radius: 6px;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 1px #1a4975;
  }

  @media (max-width: 767px) {
    height: 30px;
    padding: 5px;
    width: 100%;
  }
`;

export const CustomDDContryWithFlagAddFund = styled.div`
  display: flex;
  width: 100%;
  ${({ item }) =>
    item ? `justify-content: initial;` : `justify-content: space-between;`}
  align-items: center;
  padding: 13px;
`;

export const CustomBtnContentDboard = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  img {
    height: 21px;
    width: 29px;
    margin-right: 6px;
    object-fit: cover;
    border-radius: 5px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  @media (max-width: 767px) {
    margin-right: 126px;

    img {
      height: 21px;
      width: 29px;
      margin-right: 10px !important;
    }
    span {
      font-size: 12px !important;
    }
  }
`;

export const CustomBtnContentAddFund = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 24px;
  img {
    height: 21px;
    width: 29px;
    margin-right: 6px;
    object-fit: cover;
    border-radius: 5px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-right: 16px;
  }

  @media (max-width: 767px) {
    margin-right: 126px;

    img {
      height: 30px;
      width: 30px;
      margin-right: 10px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
`;

export const IconWrapperAddFund = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomDDSearchInputDboard = styled.input`
  box-sizing: border-box;
  background-image: url("https://cashremitlogo.s3.us-west-2.amazonaws.com/searchicon.png");
  background-position: 12px 15px;
  background-repeat: no-repeat;
  color: #1a314c;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  width: 150px;
  padding: 13px 20px 13px 45px;
  border: 1px solid #274972;
  border-bottom: transparent;
  &:focus {
    outline: 3px solid #ddd;
  }

  @media (max-width: 767px) {
    height: 40px;
    background-position: 12px 10px;
    width: 150px;
    font-size: 16px;
  }
`;

export const CustomDDSearchInputAddFund = styled.input`
  box-sizing: border-box;
  background-image: url("https://cashremitlogo.s3.us-west-2.amazonaws.com/searchicon.png");
  background-position: 14px 22px;
  background-repeat: no-repeat;
  color: #1a314c;
  font-size: 16px;
  font-weight: 700;
  height: 64px;
  width: 403px;
  padding: 13px 20px 13px 45px;
  border: 1px solid #274972;
  border-bottom: transparent;
  &:focus {
    outline: 3px solid #ddd;
  }

  @media (max-width: 767px) {
    height: 40px;
    background-position: 12px 10px;
    width: 150px;
    font-size: 16px;
  }
`;

export const CustomDDContentItemDboard = styled.div`
  display: flex;
  height: 60px;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &.selected {
    background-color: #1a4975;
    color: #fff;
    span {
      color: #ffffff;
    }
  }

  @media (max-width: 767px) {
    height: 50px;
  }
`;

export const CustomDDContryWithFlagOption = styled.div`
  display: flex;
  ${({ item }) =>
    item ? `justify-content: inherit;` : `justify-content: space-around;`}
  align-items: center;

  img {
    height: 36px;
    width: 36px;
    margin-right: 17px;
    object-fit: cover;
    border-radius: 50%;
  }

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 138%;
    color: #274972;
  }

  @media (max-width: 767px) {
    img {
      height: 30px;
      width: 30px;
      margin-right: 10px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
`;

export const CustomDDContryWithFlagOptionDboard = styled.div`
  display: flex;
  ${({ item }) =>
    item ? `justify-content: inherit;` : `justify-content: space-around;`}
  align-items: center;

  img {
    height: 30px;
    width: 30px;
    margin-right: 17px;
    object-fit: cover;
    border-radius: 50%;
  }

  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 138%;
    color: #274972;
  }

  @media (max-width: 767px) {
    img {
      height: 30px;
      width: 30px;
      margin-right: 10px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
`;
// END CUSTOM DROPDOWN COUNTRY

// START NUMBER FIELD
export const InputWrapper = styled.div`
  display: flex;
`;

// END NUMBER FIELD

// START RADIO WRAPPER STYLE

export const StyledFeesRadioWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const StyledExchangeRadioWrapper = styled.div`
  display: flex;
`;
// END RADIO WRAPPER STYLE

// START CURRENCY BOTTOM STYLE
export const CurrencyBottomTextWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  margin-top: 46px;
  border: solid 1px;
  padding: 20px;
  border-radius: 10px;
`;

export const RateAmountWrapper = styled.div`
  display: flex;
`;

export const PayoutText = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 160%;
  color: #1a314c;

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

export const CurrencyBottomRightText = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
// END CURRENCY BOTTOM STYLE

// END CURRENCY CONVERTER STYLE

const slideOne = keyframes` 
 0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }`;

export const StyledCurrencyConverter = styled.div`
  ${({ animate }) =>
    animate
      ? css`
          animation: 1s ${slideOne} ease-out;
        `
      : null}
  ${({ verticle }) =>
    !verticle
      ? `
  //padding: 0 20px;
  z-index: 1;
`
      : `
padding: 5px 0 20px 5px;
max-width: 768px;
    `}
`;

export const ConverterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  border-radius: 8px;
  margin-top: 15px;
  position: relative;
  top: 7rem;
  background: #ffffff;
  box-shadow: 0px 10px 250px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  margin-left: 24px;
  @media (max-width: 992px) {
    margin-top: 255px;
    border-radius: 1px;
  }
  @media (max-width: 767px) {
    top: 0;
    margin-top: 240px;
    margin-left: 0;
  }
`;

export const CurrencyHead = styled.p`
  font-size: 30px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: normal;
  color: #1a314c;
  text-transform: uppercase;
  margin-top: 18px;
  margin-left: 35px;
  margin-bottom: 10px;
  line-height: 1;
  p {
    margin: 0;
    margin-bottom: 15px;
    color: #1a314c;
  }

  @media (max-width: 599px) {
    font-size: 20px;
    font-weight: 700;
    margin-left: 30px;
    p {
      font-size: 20px;
      font-weight: 700;
    }
  }
`;
export const CurrencyHeadSmall = styled.p`
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 0;
`;

export const WrapperCurrency = styled.div`
  padding: 10px;
  .custom-class {
    padding-left: 0;
    @media (max-width: 599px) {
      padding-left: 15px;
      .ant-col-11 {
        max-width: 100%;
        flex: 0 0 100%;
        & + .ant-col-11 {
          margin-top: 15px;
        }
      }
    }
  }
`;
export const ContryWithFlag = styled.div`
  color: #000;
  display: flex;
  margin-top: 9px;
  align-items: center;
  line-height: normal;
  img {
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    margin-top: 9px;
  }
`;

export const CountrySelect = styled(Select)`
  border: none;
  height: auto;
  background-color: transparent !important;
  border-radius: 0;
  min-width: 180px;
  &.country-dd-${({ idx }) => idx} {
    &.active {
      width: 370px;
      z-index: 10;
      .ant-select-dropdown {
        width: 370px;
      }
    }
  }
  @media (max-width: 767px) {
    min-width: 130px;
  }
  .ant-select-selection-search > input {
    min-height: 60px !important;
    padding-left: 50px !important;
    @media (max-width: 767px) {
      min-height: 40px !important;
      padding-left: 32px !important;
    }
  }
  > div {
    background-color: #274972 !important;
    padding: 0 !important;
    border: none !important;
    outline: 0 !important;
    height: 55px !important;
    border-radius: 50px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    img {
      margin-right: 15px;
      border-radius: 50%;
      object-fit: cover;
      @media (max-width: 767px) {
        max-width: 20px;
        height: auto;
      }
    }
    @media (max-width: 767px) {
      height: 40px !important;
    }
  }
`;

export const CountryLabel = styled.p`
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #8995b8;
  margin-bottom: 5px;
`;
export const ContryDropdownWrapper = styled.div`
  // border: 2px solid #e9e8e9;
  // border-radius: 10px 100px 100px;
  display: flex;
  // justify-content: space-between;
  max-width: 90%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  input {
    border: 1px solid #274972;
    border-radius: 0;
    box-sizing: border-box;
    background-color: transparent;
    height: 72px;
    font-size: 28px;
    line-height: 39px;
    color: #1a4975;
    font-weight: 600;
    outline: 0;
    padding: 8px 16px;
    &:focus {
      // border-color: transparent;
      border: 1px solid #274972;
      box-shadow: none;
    }
    @media (max-width: 1536px) {
      height: 70px;
    }
    @media screen and (max-width: 599px) {
      font-size: 13px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      height: 60px;
      font-size: 16px;
    }
    @media screen and (min-width: 0px) and (max-width: 767px) {
      height: 60px;
      font-size: 14px;
      font-weight: 600;
      padding: 0 10px;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 60px;
  }
`;

export const RadioWrap = styled(Radio)`
  font-size: 16px !important;
  color: #274972 !important;
  line-height: 1 !important;
  margin-right: 30px;

  span.ant-radio + * {
    position: relative;
  }

  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    line-height: 30px !important;
    font-size: 13px !important;
  }
  @media (max-width: 599px) {
    font-size: 11.75px !important;
    margin-right: 0;
    span.ant-radio + * {
      padding-left: 10px;
      position: relative;
      top: -10px;
    }
  }
  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }
  .ant-radio-inner::after {
    border: 0;
    background-color: #274972 !important;
    width: 32px;
    height: 32px;
    top: 3px;
    left: 3px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #274972 !important;
    border-width: 1px;
  }
`;
export const CurrencyInput = styled(Input)`
  font-size: 24px;
  // border: none;
  border: 1px solid #274972;
  background-color: transparent;
  line-height: 1;
  font-weight: 600;
  color: #333333;
  padding: 13px 26px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 22px !important;
    padding: 5px !important;
  }
`;
export const RadioWrapper = styled.div`
  ${({ verticle }) =>
    !verticle
      ? ` margin: 20px 20px 15px;
          text-align: center;
          @media (max-width:599px) {
            text-align: left;
            margin: 20px 10px 15px 0px;
          }
          `
      : `
      margin: 1px 14px;
      @media (max-width:767px) {
        margin: 10px 0;
      }
  `}
  .ant-col {
    margin 5px 0;
  }
`;
export const CurrencyBottomText = styled.div`
  color: #8995b8;
  @media (max-width: 599px) {
    br {
      display: none;
    }
  }
`;
export const Rate = styled.span`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #8995b8;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 13px;
    display: block;
    line-height: 15px;
  }
`;
export const RateAmount = styled.span`
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
  color: #1a4975;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
export const RateCurrency = styled.span`
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  color: #1a314c;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 599px) {
    margin-left: 5px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 13px;
  }
`;
export const Payout = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #1a314c;
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 599px) {
    margin-left: 5px;
  }
`;
export const WrapperCurrencyBottom = styled.div`
  background: #ffffff;
  padding: 30px;
  border-top: 1px solid #8995b888;
  button {
    width: auto;
    @media (max-width: 599px) {
      min-width: 0;
      width: calc(100% - 30px);
      margin-top: 15px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 20px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 15px 15px 8px;
  }
  @media (max-width: 599px) {
    padding: 15px;
    .ant-col-16,
    .ant-col-12,
    .ant-col-8 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
`;
export const LineSperator = styled.span`
  position: absolute;
  right: 25px;
  top: 0px;
  svg {
    margin: 0 6px;
  }
  @media (max-width: 599px) {
    display: none;
  }
`;

export const Heading = styled.h1`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #1a4975;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 600;
  }
`;
export const Line = styled.hr`
  border: 2px solid #d6df22;
  border-radius: 10px;
  max-width: 100px;
  margin-top: -5px;
  margin-bottom: 16px;
  margin-left: 0;
`;
export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e8e8e8;
  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 14px;
    color: #8995b8;
  }
  tbody > tr:last-child > th,
  tbody > tr:last-child > td {
    background-color: #f7f7f9;
  }
  // tr:nth-child(even){background-color: #f2f2f2}
`;
export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    max-width: 90%;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin: 0 auto;
    padding-top: 6px;
    a {
      color: #1a4975;
    }
  }
`;

// Custom DropDown Country

export const CustomDDWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 180px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    min-width: 145px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    min-width: 130px;
  }
  @media screen and (max-width: 599px) {
    min-width: 110px;
  }
`;

export const CustomDDButton = styled.button`
  background-color: #274972;
  // border-radius: 30px 30px 30px 30px;
  width: 100%;
  color: white;
  padding: 9px;
  font-size: 18px;
  border-bottom: 1px solid #274972;
  border-right: 1px solid #274972;
  height: 72px;

  cursor: pointer;
  &:hover,
  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 1px #1a4975;
  }

  @media (max-width: 1536px) {
    height: 70px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 6px;
    height: 60px;
    font-size: 16px;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 5px;
    height: 60px;
    font-size: 14px;
  }
`;

export const CustomDDContentWrapper = styled.div`
  display: none;
  position: absolute;
  right: -117px;
  top: 0;
  background-color: #ffffff;
  overflow: auto;
  border: 1px solid #ededed;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 26%) 0px 12px 16px;

  &.show {
    display: block;
    overflow: hidden;
  }
  @media screen and (max-width: 991px) {
    max-width: 285px;
  }
  @media screen and (max-width: 599px) {
    max-width: 250px;
  }
`;

export const CustomDDContentItem = styled.div`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover,
  &.selected {
    background-color: #1a4975;
    color: #FFF;
    span {
     color: #FFFFFF; 
    }
}
  }
  @media screen and (max-width: 599px) {
    padding: 7px 16px;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      font-size: 14px;
    }
  }
`;

export const CustomDDSearchInput = styled.input`
  box-sizing: border-box;
  background-image: url("https://cashremitlogo.s3.us-west-2.amazonaws.com/searchicon.png");
  background-position: 14px 22px;
  background-repeat: no-repeat;
  font-size: 18px !important;
  font-weight: 700 !important;
  padding: 14px 20px 12px 45px !important;
  border: none;
  border-bottom: 1px solid #ddd !important;
  &:focus {
    outline: 3px solid #ddd;
  }
  @media screen and (max-width: 599px) {
    background-size: 16px;
    padding: 5px 20px 5px 45px !important;
    font-size: 15px !important;
    background-position: 14px 15px;
  }
`;

export const CustomDDContryWithFlag = styled.div`
  color: #000;
  line-height: 2.4;
  display: flex;

  ${({ item }) =>
    item ? `justify-content: inherit;` : `justify-content: space-around;`}
  align-items: center;
  img {
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
    height: 36px;
  }
`;

export const CustomDDCaret = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border: 10px solid transparent;
  &.down {
    border-top-color: black;
  }
  &.up {
    border-bottom-color: black;
  }
  &.left {
    border-right-color: black;
  }
  &.right {
    border-left-color: black;
  }
`;

export const CustomBtnContent = styled.div`
  display: flex;
  color: #ffffff;
  @media screen and (min-width: 0px) and (max-width: 767px) {
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg {
    width: 20px;
    fill: #d6df22;
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    svg {
      width: 12px;
    }
  }
`;

export const CurrencySelect = styled(Select)`
  border: none;
  height: auto;
  width: 100%;
  line-height: 1;
  background-color: transparent !important;
  border-radius: 0;
  min-width: 180px;
  box-sizing: border-box;
  font-weight: 600;
  .ant-select-selection-item {
    color: #1a4975;
  }
  .ant-select-arrow {
    display: flex;
    align-items: center;
    margin-right: 10px;
    svg {
      width: 20px;
      fill: #1a4975;
    }
    @media screen and (min-width: 0px) and (max-width: 767px) {
      svg {
        width: 12px;
      }
    }
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 16px !important;
  }

  @media screen and (min-width: 767px) {
    font-size: 22px !important;
    padding: 5px !important;
  }
  padding: 13px 26px;
  &:focus {
    outline: 3px solid #ddd;
  }
`;
export const Spacer = styled.div`
  height: ${({ height }) => height};
`;

export const SendIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const SendIconWrap = styled.div``;
