import { css } from "styled-components";

/**
 * @param @required typeSettings
 * @param @required breakpoint
 * @param min optional override minimum font size
 * @param max optional override maximum font size
 *
 * @returns css`` all css for that specific type component
 */
export const getFontStyle = (
  typeSettings = {
    color: "inherit",
    colorInverse: "inherit",
    fontSizeLg: null,
    fontSizeSm: null,
    fontWeight: null,
    lineHeight: null,
    marginBottom: null,
    textTransform: "normal"
  },
  breakpoint,
  minOverride,
  maxOverride
) => css`
  ${props => {
    const fontWeight =
      props.theme.fontWeight[props.fontWeight] || typeSettings.fontWeight;
    return css`
      color: ${props.inverse ? typeSettings.colorInverse : typeSettings.color};
      font-size: ${minOverride || typeSettings.fontSizeSm};
      font-weight: ${fontWeight};
      line-height: ${typeSettings.lineHeight};
      margin: 0 0 ${props.disableMargin ? 0 : typeSettings.marginBottom} 0;
      text-transform: ${typeSettings.textTransform};

      /* Class implementation, for dangerouslySetInnerHTML */
      &.color-brand-primary {
        color: ${({ theme }) => theme.colors.brandPrimary};
      }
      /* Prop implementation, for using text components directly */
      ${props.color === "brandPrimary" &&
        css`
          color: ${({ theme }) => theme.colors.brandPrimary};
        `}

      @media (min-width: ${breakpoint}) {
        font-size: ${maxOverride || typeSettings.fontSizeLg};
      }
    `;
  }};
`;

export const getParagraphSize = (elKey, size) => {
  switch (size) {
    case "sm":
      return {
        min: elKey.fontSizeSm,
        max: elKey.fontSizeMd
      };
    case "md":
      return {
        min: elKey.fontSizeMd,
        max: elKey.fontSizeLg
      };
    case "lg":
      return {
        min: elKey.fontSizeLg,
        max: elKey.fontSizeXl
      };
    default:
      return {
        min: elKey.fontSizeMd,
        max: elKey.fontSizeLg
      };
  }
};

export const calculateMaxWidth = (maxWidth, padding) => {
  return parseInt(maxWidth) + 2 * parseInt(padding);
};
