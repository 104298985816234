import React from "react";

const ExchangeRateSvg = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.08301 11.0334V9.59172C2.08301 7.86672 3.49134 6.45837 5.21634 6.45837H14.783C16.508 6.45837 17.9163 7.86672 17.9163 9.59172V10.7917H16.233C15.7663 10.7917 15.3413 10.975 15.033 11.2917C14.683 11.6334 14.483 12.125 14.533 12.65C14.608 13.55 15.433 14.2084 16.333 14.2084H17.9163V15.2C17.9163 16.925 16.508 18.3334 14.783 18.3334H10.2163"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.08301 10.3417V6.53342C2.08301 5.54175 2.69134 4.65838 3.61634 4.30838L10.233 1.80838C11.2663 1.41672 12.3747 2.18341 12.3747 3.29174V6.4584"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.7987 11.6418V13.3586C18.7987 13.8169 18.432 14.1919 17.9653 14.2085H16.332C15.432 14.2085 14.607 13.5502 14.532 12.6502C14.482 12.1252 14.682 11.6335 15.032 11.2918C15.3403 10.9752 15.7653 10.7919 16.232 10.7919H17.9653C18.432 10.8085 18.7987 11.1835 18.7987 11.6418Z"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83301 10H11.6663"
          stroke="#305178"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 13.75H6.95C7.48333 13.75 7.91667 14.1833 7.91667 14.7166V15.7833"
          stroke="#305178"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.51667 12.7334L2.5 13.75L3.51667 14.7667"
          stroke="#305178"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.91667 18.15H3.46667C2.93334 18.15 2.5 17.7167 2.5 17.1833V16.1167"
          stroke="#305178"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.90137 19.167L7.91804 18.1504L6.90137 17.1337"
          stroke="#305178"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ExchangeRateSvg;
