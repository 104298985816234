import React from "react";

import { default as CrAlert } from "@cashremit/alert";
import { ContentWrapper, ButtonWrapper, AlertWrapper } from "./style";
import { CloseIcon } from "../../components/SendMoney/data";

const Alert = () => {
  const [alertDismissed, setAlertDismissed] = React.useState(false);
  const buttonText = "More info";
  const buttonHref = "https://www.cashremit.com.au";

  //const closeText = " Close !";

  const handleButtonClick = () => {
    setAlertDismissed(true);
  };

  return alertDismissed ? (
    <></>
  ) : (
    <AlertWrapper>
      <CrAlert type="info">
        <ContentWrapper>
          <p>
            <strong>Welcome to CashRemit!</strong>You can now use PayID for your
            transfers. Please note that POLi is no longer supported on our
            platform. However, you can still make payments using your PayID
            email. We’ve also introduced a Wallet feature, allowing you to fund
            your wallet and make payments at a later time.
          </p>
          <ButtonWrapper>
            <a href={buttonHref} target="_blank" rel="noopener noreferrer">
              {buttonText}
            </a>
            <a href onClick={() => handleButtonClick()}>
              {<CloseIcon />}
            </a>
          </ButtonWrapper>
        </ContentWrapper>
      </CrAlert>
    </AlertWrapper>
  );
};
export default Alert;
