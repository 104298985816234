import React from "react";

const StarSvg = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6501 8.68339L28.0001 13.3834C28.3167 14.0334 29.1667 14.6501 29.8834 14.7834L34.1334 15.4834C36.8501 15.9334 37.4834 17.9001 35.5334 19.8667L32.2167 23.1834C31.6667 23.7334 31.3501 24.8167 31.5334 25.6001L32.4834 29.7001C33.2334 32.9334 31.5001 34.2001 28.6501 32.5001L24.6667 30.1334C23.9501 29.7001 22.7501 29.7001 22.0334 30.1334L18.0501 32.5001C15.2001 34.1834 13.4667 32.9334 14.2167 29.7001L15.1667 25.6001C15.3167 24.8001 15.0001 23.7167 14.4501 23.1667L11.1334 19.8501C9.1834 17.9001 9.81673 15.9334 12.5334 15.4667L16.7834 14.7667C17.5001 14.6501 18.3501 14.0167 18.6667 13.3667L21.0167 8.66672C22.3001 6.13339 24.3667 6.13339 25.6501 8.68339Z"
          fill="#305178"
        />
        <path
          d="M13.333 9.58325H3.33301C2.64967 9.58325 2.08301 9.01659 2.08301 8.33325C2.08301 7.64992 2.64967 7.08325 3.33301 7.08325H13.333C14.0163 7.08325 14.583 7.64992 14.583 8.33325C14.583 9.01659 14.0163 9.58325 13.333 9.58325Z"
          fill="#305178"
        />
        <path
          d="M8.33301 32.9167H3.33301C2.64967 32.9167 2.08301 32.3501 2.08301 31.6667C2.08301 30.9834 2.64967 30.4167 3.33301 30.4167H8.33301C9.01634 30.4167 9.58301 30.9834 9.58301 31.6667C9.58301 32.3501 9.01634 32.9167 8.33301 32.9167Z"
          fill="#305178"
        />
        <path
          d="M4.99967 21.25H3.33301C2.64967 21.25 2.08301 20.6833 2.08301 20C2.08301 19.3167 2.64967 18.75 3.33301 18.75H4.99967C5.68301 18.75 6.24967 19.3167 6.24967 20C6.24967 20.6833 5.68301 21.25 4.99967 21.25Z"
          fill="#305178"
        />
      </svg>
    </>
  );
};

export default StarSvg;
