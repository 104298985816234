const Reducer = (state, action) => {
	switch (action.type) {
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: action.payload
			};
		case 'ADD_NOTIFICATION':
			return {
				...state,
				notifications: state.notifications.concat(action.payload)
			};
		case 'REMOVE_NOTIFICATION':
			return {
				...state,
				notifications: state.notifications.filter(notification => notification._id !== action.payload)
			};
		case 'SET_ERROR':
			return {
				...state,
				error: action.payload
			};
		case 'SET_USER':
			return {
				...state,
				user: action.payload
			};
		case 'REMOVE_USER':
			return {
				...state,
				user: null
			};
		default:
			return state;
	}
};

export default Reducer;