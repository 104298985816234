import React from "react";
import PropTypes from "prop-types";
import {
  ConverterWrapper,
  CurrencyHead,
  CurrencyHeadSmall,
} from "./CurrencyConverter.style";
const CurrencyConverterFrame = ({ cHead, cSubHead, children }) => {
  return (
    <ConverterWrapper>
      <CurrencyHead>
        {cHead} {cSubHead}
        <CurrencyHeadSmall>
        </CurrencyHeadSmall>
      </CurrencyHead>
      {children}
    </ConverterWrapper>
  );
};

CurrencyConverterFrame.propTypes = {
  cHead: PropTypes.string.isRequired,
  cSubHead: PropTypes.string.isRequired,
};

export default CurrencyConverterFrame;
