import styled from "styled-components";

export const AlertWrapper = styled.div`
  display: flex;
  @media (max-width: 680px) {
    position: fixed;
    top: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xxs};

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-left: ${({ theme }) => theme.space.xs};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  justify-content: space-around;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-left: ${({ theme }) => theme.space.md};
    margin-top: 0;
  }
`;
