import React from "react";

const BankingSecurityCheckSvg = () => {
  return (
    <>
      <svg
        width="106"
        height="125"
        viewBox="0 0 106 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.3309 14.4065L59.4142 1.70318C55.8992 0.408184 50.1642 0.408184 46.6492 1.70318L12.7326 14.4065C6.19591 16.8732 0.892578 24.5198 0.892578 31.4882V81.4382C0.892578 86.4332 4.16091 93.0315 8.16925 95.9915L42.0859 121.337C48.0676 125.838 57.8726 125.838 63.8542 121.337L97.7709 95.9915C101.779 92.9698 105.048 86.4332 105.048 81.4382V31.4882C105.109 24.5198 99.8059 16.8732 93.3309 14.4065ZM74.4609 48.9398L47.9442 75.4565C47.0192 76.3815 45.8476 76.8132 44.6759 76.8132C43.5042 76.8132 42.3326 76.3815 41.4076 75.4565L31.5409 65.4665C29.7526 63.6782 29.7526 60.7182 31.5409 58.9298C33.3292 57.1415 36.2892 57.1415 38.0776 58.9298L44.7376 65.5899L67.9859 42.3415C69.7742 40.5532 72.7342 40.5532 74.5226 42.3415C76.3109 44.1298 76.3109 47.1515 74.4609 48.9398Z"
          fill="#305178"
        />
      </svg>
    </>
  );
};

export default BankingSecurityCheckSvg;
