import React from "react";
import {
  Banner,
  TopNavWrapper,
  Heading,
  SeamLess,
  Text,
  PageBanner,
  BannerTextWrapper,
} from "./HomeBanner.style";
import PropTypes from "prop-types";
import { Grid as Container } from "react-flexbox-grid";
import { Box, Grid, GridItem, Image } from "@chakra-ui/react";

const HomeBanner = ({
  brandImg,
  brandImgMobile,
  isbgImage,
  topNavComp,
  heading,
  title,
  description,
  children,
}) => (
  <>
    {isbgImage ? (
      <>
        <>{topNavComp}</>
        <Banner brandImg={brandImg} brandImgMobile={brandImgMobile}>
          <Container margin="0">
            <Grid templateColumns="repeat(12, 1fr)" gap={6}>
              <GridItem
                colStart={{ base: 1, lg: 1 }}
                colEnd={{ base: 13, lg: 9 }}
              >
                <Box sx={{ display: "inline-block", w: "100%" }}>
                  <BannerTextWrapper>
                    <Box
                      sx={{ display: "flex", flexDirection: "column" }}
                      alignItems={{ base: "center", md: "flex-start" }}
                    >
                      {heading && <Heading>{heading}</Heading>}
                      <SeamLess>{title}</SeamLess>
                      <Text>{description}</Text>
                    </Box>

                    {children}
                  </BannerTextWrapper>
                </Box>
              </GridItem>

              <GridItem
                colStart={{ base: 0, lg: 9 }}
                colEnd={{ base: 0, lg: 13 }}
                display={{ base: "none", lg: "block" }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    w: "100%",
                  }}
                >
                  <Image
                    src="/assets/girl-messages-via-mobile.png"
                    alt="girl-messages-via-mobile"
                    boxSize={{
                      base: "900px",
                      lg: "500",
                      xl: "750px",
                      "2xl": "900px",
                    }}
                    objectFit="contain"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: "2rem",
                    }}
                  />
                </Box>
              </GridItem>
            </Grid>
          </Container>
        </Banner>
      </>
    ) : (
      <PageBanner>
        <TopNavWrapper>{topNavComp}</TopNavWrapper>
        {children}
      </PageBanner>
    )}
  </>
);

HomeBanner.propTypes = {
  brandImg: PropTypes.string,
  brandImgMobile: PropTypes.string,
  isbgImage: PropTypes.bool,
  topNavComp: PropTypes.node,
  heading: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.string,
};

export default HomeBanner;
