import styled, { css } from "styled-components";
import { stripUnit } from "polished";

const logoSizes = {
  // pill
  brandbg: {
    ratio: 0.681,
    sizes: {
      xs: "72px",
      sm: "92px",
      md: "120px",
      lg: "160px",
    },
  },
  // wordmark
  whitebg: {
    ratio: 0.615,
    sizes: {
      xs: "72px",
      sm: "92px",
      md: "120px",
      lg: "160px",
    },
  },
};

export const LogoStyled = styled.svg`
  cursor: pointer;
  ${({ logoType, size }) => {
    const width = logoSizes[logoType].sizes[size];
    const heightVal = stripUnit(width) * logoSizes[logoType].ratio;
    const height = `${Math.round(heightVal)}px`;

    return css`
      width: ${width};
      /* height: ${height}; */
      height: 40px;
    `;
  }};
`;
