import React from "react";
import { LinkList, NavLink, NavigationWrapper, Expander } from "./Footer.style";

const NavigationColumn = ({ title, links, dataKey }) => (
  <NavigationWrapper data-key={dataKey}>
    <Expander>{title}</Expander>
    <LinkList role="menu" aria-label={title}>
      {links.map((link, index) => (
        <NavLink key={index}>{link}</NavLink>
      ))}
    </LinkList>
  </NavigationWrapper>
);

export default NavigationColumn;
