import React from "react";
import { LogoStyled } from "../Logo.style";

const Pill = ({ size, className, type }) => {
  return (
    <LogoStyled
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      className={className}
      logoType={type}
      dataName="Layer 1"
      viewBox="0 0 1456.4 327.423"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d6df22" />
          <stop offset="1" stopColor="#c0c646" />
        </linearGradient>
      </defs>
      <g id="objects" transform="translate(-3463.18 -596.592)">
        <g id="Group_4" dataName="Group 4">
          <g id="Group_1" dataName="Group 1">
            <text
              id="CASH_REMIT"
              dataName="CASH REMIT"
              transform="translate(3797.58 833.113)"
              fill="#fff"
              fontSize="203"
              fontFamily="Poppins-SemiBold, Poppins"
              fontWeight="600"
              letterSpacing="-0.05em"
            >
              <tspan x="0" y="0" fill="#d6df22">
                CASH
              </tspan>
              <tspan y="0" fill="#fff">
                REMIT
              </tspan>
              <tspan y="0" fill="#fff"></tspan>
            </text>
          </g>
          <g id="Group_3" dataName="Group 3">
            <path
              id="Path_1"
              dataName="Path 1"
              d="M3587.589,917.658l-100.683-58.13a47.45,47.45,0,0,1-23.726-41.094V702.174a47.453,47.453,0,0,1,23.726-41.1l100.683-58.129a47.449,47.449,0,0,1,47.452,0l100.684,58.129a47.453,47.453,0,0,1,23.726,41.1v116.26a47.45,47.45,0,0,1-23.726,41.094l-100.684,58.13A47.454,47.454,0,0,1,3587.589,917.658Z"
              fill="#fff"
            />
            <g id="Group_2" dataName="Group 2">
              <path
                id="Path_2"
                dataName="Path 2"
                d="M3605.7,801.868l-28.1-16.225a10.525,10.525,0,0,1-5.263-9.115V744.08a10.524,10.524,0,0,1,5.263-9.115l28.1-16.225a10.525,10.525,0,0,1,10.525,0l28.1,16.225a10.526,10.526,0,0,0,10.525,0l33.526-19.356a7.989,7.989,0,0,0,0-13.837l-73.42-42.39a7.989,7.989,0,0,0-7.989,0l-81.409,47a7.99,7.99,0,0,0-3.995,6.919v94a7.99,7.99,0,0,0,3.995,6.919l81.409,47a7.989,7.989,0,0,0,7.989,0l73.42-42.389a7.989,7.989,0,0,0,0-13.837l-33.526-19.356a10.526,10.526,0,0,0-10.525,0l-28.1,16.225A10.525,10.525,0,0,1,3605.7,801.868Z"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </g>
      </g>
    </LogoStyled>
  );
};
export default Pill;
