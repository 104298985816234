import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #ecf2f8;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 5em 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10em 0;
  }
`;

export const AppScreenWrapper = styled.div``;

export const IconGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 0 auto;
  text-align: center;
`;

export const SubText = styled.p`
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  max-width: ${({ theme }) => theme.breakpoints.md};
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 ${({ theme }) => theme.space.md} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 0 ${({ theme }) => theme.space.lg} 0;
  }
`;

export const DesktopGrid = styled.div``;

export const Title = styled.h4`
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  color: #1a4975;
  font-family: "Poppins", sans-serif;
`;

export const TextAlign = styled.div`
  display: flex;
  align-items: center;
`;

export const AppDemoWrapper = styled.div`
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 90px 0px;

  display: inline-block;
  width: 100%;

  /* svg {
    position: absolute;
    z-index: -1;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    margin-top: 22px;
  } */
  @media (max-width: 768px) {
    padding: 90px 0px;
  }
  @media (max-width: 599px) {
    padding: 45px 0px;
  }
`;

export const AppTitle = styled.h1`
  line-height: 40px;
  color: #1a314c;

  padding: 0 72px;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 700;
  padding: 0 0 0 1.5em;
  max-width: 585px;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    line-height: 26px;
    font-size: 20px;
  }

  @media (max-width: 599px) {
    padding: 0;
  }
`;

export const AppSubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #8995b8;

  font-family: "Poppins", sans-serif;
  padding: 0 0 0 3em;
  max-width: 585px;
  margin-bottom: 2em;

  @media (max-width: 1199px) {
    padding: 0 0 0 2em;
  }

  @media (max-width: 599px) {
    padding: 0;
  }
`;

export const Link = styled.a`
  display: flex;
  padding-bottom: 22px;
  max-width: 585px;

  /* padding-left: 3em; */

  /* @media (max-width: 1199px) {
    padding: 0 0 0 2em;
  } */

  @media (max-width: 599px) {
    padding: 0;
  }
`;
export const AppDemoImg = styled.img`
  /* padding: 33px 44px 0; */
  height: 625px;
  object-fit: cover;
  margin-left: 1em;
  position: relative;
  max-width: 600px;
  width: 100%;
  border-radius: 10px;

  @media (max-width: 1199px) {
    margin-left: 0;
    padding: 0;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }

  @media (max-width: 599px) {
    position: relative;
    top: 0;
  }
`;

export const AppIMGWrapper = styled.img`
  display: flex;
  margin-right: -24px;
  @media (max-width: 599px) {
    max-width: 125px;
    margin-right: 15px;
  }
`;
export const GridItems = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  width: 315px;
  margin: 0 auto;
  text-align: center;
  > div {
    width: 100%;
  }

  @media (min-width: 991px) {
    :first-child + div:after {
      left: -50px;
    }
    :first-child + div:before {
      right: -50px;
    }
  }

  @media (max-width: 990px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 30%;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  // text-align: center;
  padding-bottom: 2em;
  // h2 {
  //   font-size: 1.5rem;
  // }
  // h4 {
  //   font-size: 1rem;
  //   line-height: 0;
  // }

  /* @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    padding-bottom: 4em;
  } */
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 124%;
  text-align: center;
  text-transform: uppercase;
  color: #305178;
  margin-bottom: 0;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;

  /* @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 48px;
  } */

  @media (max-width: 1199px) {
    font-size: 28px;
  }

  @media (max-width: 575px) {
    font-size: 22px;
  }
`;

export const H4 = styled.h4`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #305178;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

export const AppWrapper = styled.div`
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &.a-link {
    display: none;
  }
`;

export const AppHeading = styled.p`
  padding: 0 0 0 1.5em;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #274972;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 65px;
  }
`;

export const ArrowImage = styled.img`
  max-height: 130px;
  max-width: 130px;
  margin-top: 65px;
  display: flex;
  @media (max-width: 1199px) {
    margin-top: 22px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: 3rem;
`;
