import styled from "styled-components";

export const Navigation = styled.nav`
  /* display: flex; */
  position: relative;
  /* justify-content: flex-start; */
  /* margin: 0 auto; */
  padding: 0 10px 0;
  z-index: 2;
  /* align-self: center; */
  max-width: 100%;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  background-color: transparent;
  /* align-items: center; */
  font-family: "Poppins", sans-serif;
  /* border-bottom: 1px solid #ffffff3d; */

  /* display: inline-block; */
  width: 100%;
  height: 100%;

  /* @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  } */

  @media (max-width: 992px) {
    padding: 0px;
    & > svg {
      width: 150px;
    }
  }
`;

// new

export const LogoWrapWeb = styled.div`
  display: inline-block;
  position: relative;

  ::after {
    position: absolute;
    content: "";
    right: -25px;
    top: 5px;
    bottom: 0;
    height: 75%;
    width: 1px;
    background: #b9c4d1;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const LogoWrapMobile = styled.div`
  display: none;

  /* display: none;
  position: absolute;
  right: 0;
  left: 0;
  top: 24px;
  justify-content: center; */

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;

  @media (max-width: 992px) {
    display: flex;
    position: absolute;
    right: 20px;
    top: 0px;
  }
  /* @media (max-width: 599px) {
    top: 0;
  } */
`;

// export const Navbox = styled.div`
//   display: flex;
//   height: 100%;
//   justify-content: flex-end;
//   align-items: center;
//   padding: 15px;
//   margin-left: 20px;

//   @media (max-width: 992px) {
//     flex-direction: column;
//     position: absolute;
//     width: 100%;
//     justify-content: flex-start;
//     padding-top: 2vh;
//     background-color: #fff;
//     transition: all 0.3s ease-in;
//     top: 0;
//     margin-left: 0;
//     left: ${(props) => (props.open ? "-100%" : "0")};
//     z-index: 1000;
//     margin-top: 8em;
//     height: 100vh;
//   }
// `;

export const Navbox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 15px;

  @media (max-width: 992px) {
    flex-direction: column;
    position: absolute;
    justify-content: flex-start;
    padding-top: 2vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 0;
    margin-left: 0;
    left: ${(props) => (props.open ? "-100%" : "0")};
    z-index: 1000;
    margin-top: 6em;
    height: 100vh;
  }
`;

export const Hamburger = styled.div`
  /* background-color: ${(props) => (props.isBgColor ? "#1a4975" : "white")}; */
  background-color: #305178;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    /* background-color: ${(props) =>
      props.isBgColor ? "#1a4975" : "white"}; */
    background-color: #305178;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? "0" : "1")};
    transform: ${(props) => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;
// NavbarLinks

export const NavItem = styled.li`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;
  margin-right: 3em;
  a {
    // color: ${(props) => (props.isBgColor ? "#1a4975" : "white")};
    color: #305178;
  }

  @media (max-width: 992px) {
    a {
      color: ${({ theme }) => theme.palette.Green100};
    }
  }

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #373f49;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: #ffffff;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
    margin: 0 auto;
    color: ${({ theme }) => theme.palette.Green100};
  }
`;

export const SelectContry = styled.select`
  display: flex;
  -webkit-appearance: none;
  border: none;
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => (props.isBgColor ? "#1a4975" : "white")};
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(-45deg, transparent 50%, currentColor 50%);
  background-size: 0.275em 0.275em;
  background-position: calc(100% - 0.975em) center, calc(100% - 0.7em) center;
  background-repeat: no-repeat;
  font-size: 22px;
  padding-right: 40px;
  padding-left: 14px;
  cursor: pointer;
  background-color: transparent;
  border: solid 1px ${({ theme }) => theme.palette.Yellow10};
  padding: 5px;
  border-radius: 8px;

  :after {
    .Streamline_Icon {
      display: inline-flex;
      padding-left: 13px;
      position: relative;
      top: 3px;
    }
  }

  @media (max-width: 992px) {
    color: ${({ theme }) => theme.palette.Green100};
  }
`;

export const SigupBTN = styled.li`
  background: ${({ theme }) => theme.palette.Yellow10};
  padding: 14px 32px !important;
  // border-radius: 50px;
  color: ${({ theme }) => theme.palette.Green100};
  /* box-shadow: 1px 15px 20px -10px ${({ theme }) =>
    theme.palette.Yellow10}; */
  border: none;
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600 !important;

  border-radius: 50px;

  a {
    color: ${({ theme }) => theme.palette.Green100};
  }
  @media (max-width: 1444px) {
    padding: 15px 40px !important;
  }
  @media (max-width: 1320px) {
    padding: 15px 30px !important;
  }
  @media (max-width: 992px) {
    margin-top: 1em;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
  }
  li {
    color: #666;
    font-weight: 700;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  select,
  li {
    padding: 5px 16px;
    white-space: nowrap;
    font-size: 16px;
    border: none;
    margin: 0;
    letter-spacing: 1px !important;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    :focus {
      border: none;
      outline: 0;
    }
    &:last-child {
      /* margin-left: 6%; */
      /* font-weight: 600; */
      margin-left: 10px;
      @media (max-width: 992px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
    &:nth-last-child(2) {
      // margin-left: 6%;
      a {
        // color: #1a4975;
      }
      font-weight: 500;
      @media (max-width: 992px) {
        margin-left: 0;
      }
    }
    @media (max-width: 1199px) {
      /* font-size: 12px; */
      padding: 5px 15px;
    }
    @media (max-width: 992px) {
      font-size: 16px;
      padding: 10px;
    }
  }
  select {
    padding-right: 10px;
    margin-right: 10px;
    option {
      color: #666;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: ${({ theme }) => theme.palette.Green100};
  }
`;

export const SendTo = styled.span`
  color: ${(props) => (props.isBgColor ? "#1a4975" : "white")};
  padding: 5px 26px;
  white-space: nowrap;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 16px;

  @media (max-width: 992px) {
    color: ${({ theme }) => theme.palette.Green100};
  }

  // @media (max-width: 1199px) {
  //   font-size: 12px;
  //   padding: 5px 15px;
  // }

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 5px 10px;
  }
`;

export const PopOverButton = styled.a`
  background: transparent;
  color: white;
  border: none;
  font-weight: 500;
  margin-left: 14px;
  font-size: 16px;
  letter-spacing: 1px !important;
`;

export const PopoverText = styled.p`
    display: flex;
    border-bottom: solid 1px#dddddd;
    color: #2d4c75;
    text-transform: none;
    font-size: 18px;
    font-weight: 600;
    padding: 2em;
  }
`;
