import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";
import React from "react";

const DeliveryMethodNew = (props) => {
  const { defaultValue, options } = props;

  // console.log("options", options);
  // console.log("defaultValue", defaultValue);

  // const optionss = ["react", "vue", "svelte"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: defaultValue ? defaultValue : "ACCOUNT",
    onChange: (value) => {
      // console.log(value); // Log the selected value to the console
      props.onChange(value);
    },
  });

  const group = getRootProps();

  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            bg: "#305178",
            color: "white",
            borderColor: "#305178",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {options && (
        <HStack {...group} sx={{ label: { mb: 0 }, mt: 6 }}>
          {options.map((option) => {
            const radio = getRadioProps({ value: option.value });
            return (
              <RadioCard key={option.value} {...radio}>
                {option.label}
              </RadioCard>
            );
          })}
        </HStack>
      )}
    </>
  );
};

export default DeliveryMethodNew;
