import React from "react";
import PropTypes from "prop-types";
import { Wrapper, ChildWrapper, IconWrapper, PayWrapper } from "./Button.style";

const Button = ({
  children,
  onClick,
  className,
  type,
  icon,
  amountTopay,
  ...props
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      icon={icon}
      type={type}
      amountTopay={amountTopay}
      {...props}
    >
      <ChildWrapper icon={icon} amountTopay={amountTopay}>
        {children}
        {(amountTopay || icon) && (
          <IconWrapper>
            {amountTopay && <PayWrapper>{amountTopay}</PayWrapper>}{" "}
            {icon && icon}
          </IconWrapper>
        )}
      </ChildWrapper>
    </Wrapper>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.node,
  amountTopay: PropTypes.string,
};

Button.defaultProps = {
  icon: null,
  type: "button",
};

export default Button;
