import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@cashremit/button";
import { RightOutlined } from "@ant-design/icons";
import { Row, Col, Radio, Select } from "antd";
import {
  StyledCurrencyConverter,
  WrapperCurrency,
  CountryLabel,
  ContryDropdownWrapper,
  RadioWrapper,
  RadioWrap,
  Rate,
  RateAmount,
  RateCurrency,
  CurrencyBottomText,
  Payout,
  WrapperCurrencyBottom,
  LineSperator,
  Heading,
  Line,
  Table,
  Footer,
  CurrencySelect,
  SendIconBox,
  SendIconWrap,
  Spacer,
} from "./CurrencyConverter.style";
import NumberField from "./NumberField";
import CustomCountryDD from "./CustomCountryDD";
import { SendIconSvg } from "./assets/icons";
const { Option } = Select;

const CurrencyConverter = (props) => {
  const {
    from,
    to,
    defaultFromValue,
    bonus: bonusArr = [],
    defaultToValue,
    defaultDeliveryMethod = "ACCOUNT",
    deliveryMethodsFor = [],
    deliveryMethods = [],
    multiCurrency,
  } = props;
  const [fromCountry, setFromCountry] = useState(defaultFromValue);
  const [toCountry, setToCountry] = useState(defaultToValue);
  const [fromAmount, setFromAmount] = useState(props.defaultAmount || 1000);
  const [rate, setRate] = useState(props.rate);
  const [bonus, setBonus] = useState(0);
  const [toAmount, _toAmount] = useState((1000 - 1) * rate);
  const [fee, setFee] = useState(1);
  const verticle = props.verticle || false;
  const animate = props.animate || false;

  const inp = useRef("from");

  const setToAmount = (amt) => {
    _toAmount(amt < 0 ? 0 : amt);
  };

  useEffect(() => {
    setRate(props.rate);
    setToAmount((+fromAmount - (+props?.fees || fee)) * props.rate);
    feesCalculation(fromAmount);
    bonusRateCalculation(fromAmount - (+props.fees || fee));
  }, [props.rate, props.fees, props.bonus, props.secondaryCurrency]);

  useEffect(() => {
    switch (inp.current) {
      case "to":
        setFromAmount(toAmount / rate + fee);
        break;
      case "from":
        setToAmount((fromAmount - fee) * rate);
        break;

      default:
        break;
    }

    return () => {};
  }, [fee, fromAmount, rate, toAmount]);

  const feesCalculation = (amt) => {
    // bonusRateCalculation(amt);
    const { fees } = props;
    for (const f of fees) {
      if (amt > f.from && amt <= f.to) {
        setFee(f.rate);
        return;
      }
    }
  };

  const bonusRateCalculation = (amt) => {
    for (const b of bonusArr) {
      const { range, rate: bonusRate = 0 } = b;
      const { start, end } = range || { start: 0, end: 0 };
      if (amt >= start && amt < end && bonusRate > rate) {
        setBonus(+amt * (+bonusRate - +rate));
        return;
      }
    }
    setBonus(0);
  };

  const amountChangeHandler = (e, w) => {
    switch (w) {
      case "from":
        inp.current = "from";
        feesCalculation(e.target.value);

        bonusRateCalculation(e.target.value - fee);
        setFromAmount(e.target.value);
        setToAmount((e.target.value - fee) * rate);

        break;
      case "to":
        inp.current = "to";
        feesCalculation(e.target.value / rate);

        bonusRateCalculation(e.target.value / rate - fee);
        setToAmount(e.target.value);
        setFromAmount(e.target.value / rate + fee);

        break;
      default:
        break;
    }
  };

  const countryChangeHandler = (v, w) => {
    switch (w) {
      case "from":
        setFromCountry(v);
        props.onCountryChange({ from: v.value, to: toCountry.value });
        break;
      case "to":
        setToCountry(v);
        props.onCountryChange({ from: fromCountry.value, to: v.value });
        break;
      default:
        break;
    }
  };

  const onCurrencyRadioChangeHandler = (val) => {
    if (
      multiCurrency &&
      Array.isArray(multiCurrency) &&
      multiCurrency.length > 0
    ) {
      setToCountry(val);
      props.onCountryChange({ from: fromCountry.value, to: val });
    }
  };

  const downArrow = () => (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlspacespace="preserve"
    >
      <g>
        <g>
          <path
            d="M505.752,123.582c-8.331-8.331-21.839-8.331-30.17,0L256,343.163L36.418,123.582c-8.331-8.331-21.839-8.331-30.17,0
        s-8.331,21.839,0,30.17l234.667,234.667c8.331,8.331,21.839,8.331,30.17,0l234.667-234.667
        C514.083,145.42,514.083,131.913,505.752,123.582z"
          />
        </g>
      </g>
    </svg>
  );

  return (
    <StyledCurrencyConverter
      {...props.className}
      verticle={verticle}
      animate={animate}
    >
      <WrapperCurrency>
        {verticle && (
          <>
            <Heading> How much would you like to send ? </Heading>
            <Line />
          </>
        )}
        <Row justify="space-between custom-class" gutter="10">
          <Col span={verticle ? 24 : 11}>
            <CountryLabel style={{ paddingLeft: "5px" }}>
              You Send{" "}
            </CountryLabel>
            <ContryDropdownWrapper>
              <CustomCountryDD
                data={from}
                defaultValue={fromCountry.value}
                idx="from"
                onChange={(v) => countryChangeHandler(v, "from")}
              />
              <NumberField
                defaultValue={fromAmount}
                value={fromAmount}
                size="large"
                onChange={(e) => amountChangeHandler(e, "from")}
              />
            </ContryDropdownWrapper>
          </Col>
          {verticle && (
            <Col span="24">
              <RadioWrapper verticle={verticle}>
                <Radio.Group
                  defaultValue={toCountry.value}
                  onChange={(e) => onCurrencyRadioChangeHandler(e.target.value)}
                >
                  {(!multiCurrency ||
                    (multiCurrency &&
                      Array.isArray(multiCurrency) &&
                      multiCurrency.length < 1)) && (
                    <Col span={24}>
                      <RadioWrap value={1}>
                        Fx Fees AUD {fee} Estimated
                      </RadioWrap>
                    </Col>
                  )}
                  <Col key={toCountry.value} span={24}>
                    <RadioWrap value={toCountry.value}>
                      Our Exchange Rate {fromCountry.name} 1 ={" "}
                      {`${toCountry.name} ${rate}`}
                    </RadioWrap>
                  </Col>
                  {multiCurrency &&
                    Array.isArray(multiCurrency) &&
                    multiCurrency.map((c, i) => (
                      <Col key={c.currency} span={24}>
                        <RadioWrap value={c.currency}>
                          Our Exchange Rate {fromCountry.name} 1 ={" "}
                          {c.currency || toCountry.name} {c.rate || rate}
                        </RadioWrap>
                      </Col>
                    ))}
                </Radio.Group>
              </RadioWrapper>
            </Col>
          )}
          {!verticle && (
            <Col span={2}>
              <SendIconBox>
                <Spacer height="25px" />
                <SendIconWrap>
                  <SendIconSvg height="32px" />
                </SendIconWrap>
              </SendIconBox>
            </Col>
          )}
          <Col span={verticle ? 24 : 11}>
            <CountryLabel>Recipient gets</CountryLabel>
            <ContryDropdownWrapper>
              <CustomCountryDD
                data={
                  props.secondaryCurrency && props.secondaryCurrency.length
                    ? props.secondaryCurrency
                    : to
                }
                defaultValue={toCountry.value}
                idx="to"
                onChange={(v) => countryChangeHandler(v, "to")}
              />
              <NumberField
                defaultValue={toAmount}
                value={toAmount}
                size="large"
                onChange={(e) => amountChangeHandler(e, "to")}
              />
            </ContryDropdownWrapper>
          </Col>
          {verticle &&
            multiCurrency &&
            Array.isArray(multiCurrency) &&
            multiCurrency.length > 0 && (
              <Col span="24">
                <CountryLabel>Recipient gets in currency</CountryLabel>
                <ContryDropdownWrapper>
                  <CurrencySelect
                    size="large"
                    bordered={false}
                    defaultValue={toCountry.value}
                    onChange={onCurrencyRadioChangeHandler}
                    suffixIcon={downArrow}
                    dropdownClassName="dd-currency"
                  >
                    <Option key={toCountry.value} value={toCountry.value}>
                      {toCountry.name}
                    </Option>
                    {multiCurrency &&
                      Array.isArray(multiCurrency) &&
                      multiCurrency.map((c) => (
                        <Option key={c.currency} value={c.currency}>
                          {c.currency}
                        </Option>
                      ))}
                  </CurrencySelect>
                </ContryDropdownWrapper>
              </Col>
            )}
        </Row>

        {/* children */}
        {props.children}

        {!verticle && (
          <Row>
            <Col span="24">
              <RadioWrapper>
                <Radio.Group
                  defaultValue={toCountry.value}
                  onChange={(e) => onCurrencyRadioChangeHandler(e.target.value)}
                >
                  {(!multiCurrency ||
                    (multiCurrency &&
                      Array.isArray(multiCurrency) &&
                      multiCurrency.length < 1)) && (
                    <RadioWrap value={1}>Fx Fees AUD {fee} Estimated</RadioWrap>
                  )}
                  <RadioWrap key={toCountry.value} value={toCountry.value}>
                    Our Exchange Rate {fromCountry.name} 1 = {toCountry.name}{" "}
                    {rate}
                  </RadioWrap>
                  {multiCurrency &&
                    Array.isArray(multiCurrency) &&
                    multiCurrency.map((c) => (
                      <RadioWrap key={c.currency} value={c.currency}>
                        Our Exchange Rate {fromCountry.name} 1 ={" "}
                        {c.currency || toCountry.name} {c.rate || rate}
                      </RadioWrap>
                    ))}
                </Radio.Group>
              </RadioWrapper>
            </Col>
          </Row>
        )}
      </WrapperCurrency>
      {!verticle && (
        <WrapperCurrencyBottom>
          <Row>
            <Col span="16">
              <Row>
                <Col span="12">
                  <CurrencyBottomText>
                    <Rate>Exchange rate </Rate>
                    <br />
                    <RateCurrency></RateCurrency>
                    <RateAmount>
                      {/* {toCountry}: <span></span> */}
                      {fromCountry.name}/{toCountry.name}
                      {" : "} {rate}
                    </RateAmount>
                    <LineSperator>
                      <svg width="1" height="60" viewBox="0 0 1 60">
                        <line
                          id="Line_1"
                          data-name="Line 1"
                          y2="60"
                          transform="translate(0.5)"
                          fill="none"
                          stroke="#c9c9c9"
                          strokeWidth="1"
                        />
                      </svg>
                    </LineSperator>
                  </CurrencyBottomText>
                </Col>
                <Col span="12">
                  <CurrencyBottomText>
                    <Rate>Our Fees </Rate>
                    <br />
                    <RateAmount>
                      {fromCountry.name}: <span></span>
                      {fee}
                    </RateAmount>
                    {/* <Payout>Payout in 30 mins</Payout> */}
                  </CurrencyBottomText>
                </Col>
              </Row>
            </Col>
            <Col span="8">
              <Button
                onClick={() =>
                  props.onButtonClick({
                    amount: +fromAmount,
                    fee,
                    rate,
                    bonus,
                    receive: +toAmount,
                  })
                }
              >
                {" "}
                {props.buttonText || `View More`}
              </Button>
            </Col>
          </Row>
        </WrapperCurrencyBottom>
      )}
      {verticle && (
        <>
          <Heading> Payment Summary </Heading>
          <Line />
          <Table>
            <tbody>
              <tr>
                <th>Amount</th>
                <td>
                  {fromCountry.name}{" "}
                  {(+fromAmount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <th>Estimated Transfer Fee</th>
                <td>
                  {fromCountry.name}{" "}
                  {(+fee).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <th>Converted Amount</th>
                <td>
                  {" "}
                  {fromCountry.name}{" "}
                  {(+fromAmount - +fee).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              {/* <tr>
								<th>Amount to Receive</th>
								<td>
									{toCountry}{" "}
									{(+toAmount).toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
								</td>
							</tr> */}
              <tr>
                <th>Bonus</th>
                <td>
                  {toCountry.name}{" "}
                  {(+bonus).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <th>Total Amount to Receive</th>
                <td>
                  <strong style={{ fontWeight: 600 }}>
                    {toCountry.name}{" "}
                    {(+toAmount + +bonus).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
          <Footer>
            <Button
              onClick={() =>
                props.onButtonClick({
                  amount: +fromAmount,
                  fee,
                  bonus,
                  rate,
                  receive: +toAmount,
                })
              }
              icon={<RightOutlined />}
            >
              {props.buttonText || `Continue`}
            </Button>
            <p>
              By continuing this transaction, You accept CashRemit{" "}
              <a href="/terms-and-conditions">Terms and Conditions</a> and{" "}
              <a href="privacy-policy">Privacy Policy</a> related to our
              services.
            </p>
          </Footer>
        </>
      )}
    </StyledCurrencyConverter>
  );
};

CurrencyConverter.propTypes = {
  from: PropTypes.array.isRequired,
  to: PropTypes.array.isRequired,
  fees: PropTypes.array.isRequired,
  bonusRate: PropTypes.string,
  bonusRange: PropTypes.object,
  rate: PropTypes.number.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  verticle: PropTypes.bool,
  animate: PropTypes.bool,
};

export default CurrencyConverter;
