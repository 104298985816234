import { Icon } from "@chakra-ui/react";
import React from "react";
// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaTwitterSquare,
// } from "react-icons/fa";

import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { SocialIcon } from "./Footer.style";

const icons = {
  facebook: BsFacebook,
  twitter: AiFillTwitterCircle,
  instagram: AiOutlineInstagram,
};

export default function SocialLink({ link }) {
  return (
    <SocialIcon
      href={link.href}
      target="_blank"
      data-key={`footer__social-icons__${link.title}`}
    >
      <Icon
        as={icons[link.title]}
        color="#D6DF22"
        fontSize={26}
        className={`${link.title}_icon`}
      />
    </SocialIcon>
  );
}
