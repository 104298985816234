import React from "react";

const LikeSvg = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9834 30.8167V13.8834C13.9834 13.2167 14.1834 12.5667 14.5501 12.0167L19.1001 5.25004C19.8167 4.16671 21.6001 3.40004 23.1167 3.96671C24.7501 4.51671 25.8334 6.35004 25.4834 7.98337L24.6167 13.4334C24.5501 13.9334 24.6834 14.3834 24.9667 14.7334C25.2501 15.05 25.6667 15.25 26.1167 15.25H32.9667C34.2834 15.25 35.4167 15.7834 36.0834 16.7167C36.7167 17.6167 36.8334 18.7834 36.4167 19.9667L32.3167 32.45C31.8001 34.5167 29.5501 36.2 27.3167 36.2H20.8167C19.7001 36.2 18.1334 35.8167 17.4167 35.1L15.2834 33.45C14.4667 32.8334 13.9834 31.85 13.9834 30.8167Z"
          fill="#305178"
        />
        <path
          d="M8.68301 10.6333H6.96634C4.38301 10.6333 3.33301 11.6333 3.33301 14.1V30.8666C3.33301 33.3333 4.38301 34.3333 6.96634 34.3333H8.68301C11.2663 34.3333 12.3163 33.3333 12.3163 30.8666V14.1C12.3163 11.6333 11.2663 10.6333 8.68301 10.6333Z"
          fill="#305178"
        />
      </svg>
    </>
  );
};

export default LikeSvg;
